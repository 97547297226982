import { Player } from "@lottiefiles/react-lottie-player";
import UnAuthorizeAnimation from "components/common/Spinner/LottieAnimation/401-error.json";

const NotFoundPageAnimation = () => {
  return (
    <Player
      autoplay
      loop
      src={UnAuthorizeAnimation}
      style={{ height: "70%", width: "70%" }}
    />
  );
};

export default NotFoundPageAnimation;
