import { Controller } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import PropTypes from "prop-types";

const AutoComplete = ({ label, name, control, options, multi }) => (
  <Controller
    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
      <Autocomplete
        fullWidth
        size="small"
        multiple={multi ?? false}
        disablePortal
        id={label}
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={options}
        getOptionLabel={(option) => option.label ?? option}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            label={label}
            helperText={error ? error.message : ""}
            error={error ? true : false}
          />
        )}
        onChange={(_, data) => onChange(data)}
        onBlur={onBlur}
      />
    )}
    name={name}
    control={control}
  />
);

AutoComplete.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool,
};

export default AutoComplete;
