import { createBrowserRouter } from "react-router-dom";
import {
  Login,
  Dashboard,
  IssuerMgmt,
  IssuerMgmtAdd,
  PaymentCampaignMgmt,
  PaymentCampaignMgmtAdd,
  ReferralCampaignMgmt,
  ReferralCampaignMgmtAdd,
  ReferralTransactionHistory,
  CampaignTransactionHistory,
  UserGroup,
  Limit,
  LimitAdd,
  Rate,
  RateAdd,
  LogStatus,
  NotFound,
  UnAuthorize,
  ReferralCodeDetailReport,
  UserRegister,
  Point,
  PointAdd,
  PointCampaignMgmt,
  PointCampaignMgmtAdd,
  OutstandingPoint,
  UserGroupCreate,
} from "./LazyRoutes";
import { Layout } from "components";
import { checkAuthLoader } from "utils";
import RestrictedRoute from "routes/RestrictedRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    id: "root",
    loader: checkAuthLoader,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },

      {
        path: "issuers/issuer-management",
        //element: ProtectedRouteElement
        children: [
          {
            index: true,
            element: <IssuerMgmt />,
          },
          {
            path: "add",
            element: (
              <RestrictedRoute
                moduleName="issuers"
                menuName="issuer management"
                operationButton="add"
              >
                <IssuerMgmtAdd />
              </RestrictedRoute>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <RestrictedRoute
                moduleName="issuers"
                menuName="issuer management"
                operationButton="edit"
              >
                <IssuerMgmtAdd />
              </RestrictedRoute>
            ),
          },
        ],
      },

      {
        path: "campaign/payment-campaign-management",
        children: [
          {
            index: true,
            element: <PaymentCampaignMgmt />,
          },
          {
            path: "add",
            element: (
              <RestrictedRoute
                moduleName="campaign mgmt"
                menuName="payment campaign mgmt"
                operationButton="add"
              >
                <PaymentCampaignMgmtAdd />
              </RestrictedRoute>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <RestrictedRoute
                moduleName="campaign mgmt"
                menuName="payment campaign mgmt"
                operationButton="edit"
              >
                <PaymentCampaignMgmtAdd />
              </RestrictedRoute>
            ),
          },
        ],
      },

      {
        path: "campaign/referral-campaign-management",
        children: [
          {
            index: true,
            element: <ReferralCampaignMgmt />,
          },
          {
            path: "add",
            element: (
              <RestrictedRoute
                moduleName="campaign mgmt"
                menuName="referral campaign mgmt"
                operationButton="add"
              >
                <ReferralCampaignMgmtAdd />
              </RestrictedRoute>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <RestrictedRoute
                moduleName="campaign mgmt"
                menuName="referral campaign mgmt"
                operationButton="edit"
              >
                <ReferralCampaignMgmtAdd />
              </RestrictedRoute>
            ),
          },
        ],
      },

      {
        path: "campaign/point-campaign-management",
        children: [
          {
            index: true,
            element: <PointCampaignMgmt />,
          },
          {
            path: "add",
            element: (
              <RestrictedRoute
                moduleName="campaign mgmt"
                menuName="point campaign mgmt"
                operationButton="add"
              >
                <PointCampaignMgmtAdd />
              </RestrictedRoute>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <RestrictedRoute
                moduleName="campaign mgmt"
                menuName="point campaign mgmt"
                operationButton="edit"
              >
                <PointCampaignMgmtAdd />
              </RestrictedRoute>
            ),
          },
        ],
      },

      {
        path: "marketing/user-group",
        children: [
          {
            index: true,
            element: <UserGroup />,
          },
          {
            path: "add",
            element: (
              <RestrictedRoute
                moduleName="marketing mgmt"
                menuName="user group"
                operationButton="add"
              >
                <UserGroupCreate />
              </RestrictedRoute>
            ),
          },

          {
            path: "edit/:id",
            element: (
              <RestrictedRoute
                moduleName="marketing mgmt"
                menuName="user group"
                operationButton="edit"
              >
                <UserGroupCreate />
              </RestrictedRoute>
            ),
          },
        ],
      },

      {
        path: "marketing/limit",
        children: [
          {
            index: true,
            element: <Limit />,
          },
          {
            path: "add",
            element: (
              <RestrictedRoute
                moduleName="marketing mgmt"
                menuName="limit"
                operationButton="add"
              >
                <LimitAdd />
              </RestrictedRoute>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <RestrictedRoute
                moduleName="marketing mgmt"
                menuName="limit"
                operationButton="edit"
              >
                <LimitAdd />
              </RestrictedRoute>
            ),
          },
        ],
      },

      {
        path: "marketing/rate",
        children: [
          {
            index: true,
            element: <Rate />,
          },
          {
            path: "add",
            element: (
              <RestrictedRoute
                moduleName="marketing mgmt"
                menuName="rate"
                operationButton="add"
              >
                <RateAdd />
              </RestrictedRoute>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <RestrictedRoute
                moduleName="marketing mgmt"
                menuName="limit"
                operationButton="edit"
              >
                <RateAdd />
              </RestrictedRoute>
            ),
          },
        ],
      },
      {
        path: "marketing/point",
        children: [
          {
            index: true,
            element: <Point />,
          },
          {
            path: "add",
            element: (
              <RestrictedRoute
                moduleName="marketing mgmt"
                menuName="rate"
                operationButton="add"
              >
                <PointAdd />
              </RestrictedRoute>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <RestrictedRoute
                moduleName="marketing mgmt"
                menuName="limit"
                operationButton="edit"
              >
                <PointAdd />
              </RestrictedRoute>
            ),
          },
        ],
      },

      {
        path: "reports",
        children: [
          {
            path: "referral-code-detail-report",
            element: <ReferralCodeDetailReport />,
          },
          {
            path: "referral-transaction-history",
            element: <ReferralTransactionHistory />,
          },
          {
            path: "campaign-transaction-history",
            element: <CampaignTransactionHistory />,
          },
          {
            path: "outstanding-points",
            element: <OutstandingPoint />,
          },
        ],
      },

      {
        path: "system",
        children: [
          {
            path: "log-status",
            element: <LogStatus />,
          },
          {
            path: "register",
            element: (
              <RestrictedRoute
                moduleName="system"
                menuName="user register"
                operationButton="add"
              >
                <UserRegister />
              </RestrictedRoute>
            ),
          },
        ],
      },
      {
        path: "unAuthorize",
        element: <UnAuthorize />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

export default router;
