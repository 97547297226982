import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const HeaderSix = ({ title }) => {
  return (
    <Typography variant="h6" component="h6" sx={{ textTransform: "uppercase" }}>
      {title}
    </Typography>
  );
};

HeaderSix.propTypes = {
  title: PropTypes.string.isRequired,
};

export default HeaderSix;
