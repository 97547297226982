import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { requestMerchants } from "actions";
import { CommonDialog, CheckboxTable, LoadingSpinner } from "components/common";
import { pgSize, pgNo } from "constants";
import Search from "./Search";

const IndividualMerchantPopup = ({
  open,
  targetMerchants,
  handleDialogBox,
  handleValue,
}) => {
  const { merchantLoading, merchants } = useSelector(
    (state) => state.PopupData,
  );
  const [searchAll, setSearchAll] = useState(true);
  const dispatch = useDispatch();

  const defaultValues = {
    merchantId: "",
    merchantName: "",
  };

  const validationSchema = yup.object({
    merchantId: yup.string().trim(),
    merchantName: yup.string().trim(),
  });

  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    requestGetMerchants(pgSize, pgNo);
  }, [searchAll]);

  const requestGetMerchants = (pageSize, pageNo) => {
    if (searchAll) {
      dispatch(
        requestMerchants(
          {
            merchantId: "",
            merchantName: "",
          },
          {
            pageSize,
            pageNo,
          },
        ),
      );

      reset();
    } else {
      dispatch(
        requestMerchants(
          {
            merchantId: watch("merchantId"),
            merchantName: watch("merchantName"),
          },
          {
            pageSize,
            pageNo,
          },
        ),
      );
    }
  };

  const getDataRows = () => {
    const data = merchants?.data?.map((merchant) => ({
      id: merchant.merchantId,
      merchantId: merchant.merchantId,
      merchantName: merchant.merchantName,
    }));

    return data ? { ...merchants, data } : null;
  };

  const columns = [
    { id: "merchantId", label: "Merchant ID", minWidth: 350 },
    { id: "merchantName", label: "Merchant Name", minWidth: 350 },
  ];

  return (
    <CommonDialog
      maxWidth="md"
      open={open}
      handleDialogBox={handleDialogBox}
      dialogTitle="Merchant"
    >
      <Box>
        {merchantLoading && <LoadingSpinner />}

        <Search
          control={control}
          handleSubmit={handleSubmit}
          setSearchAll={setSearchAll}
        />

        <CheckboxTable
          columns={columns}
          dataRows={getDataRows()}
          targetValues={targetMerchants}
          handleValueList={(status, value) => {
            handleValue(status, value);
          }}
          requestDataByPageNo={requestGetMerchants}
        />
      </Box>
    </CommonDialog>
  );
};

export default IndividualMerchantPopup;
