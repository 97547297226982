const bgImage = require("assets/img/background.svg").default;
const aplusLogo = require("assets/img/AplusLogo.svg").default;
const aplusLogoColor = require("assets/img/a_plus_logo_color.svg").default;
const loginImage = require("assets/img/login-image.gif");
const lockIcon = require("assets/img/lock.svg");
const personIcon = require("assets/img/lock.svg");

export default {
  bgImage,
  aplusLogo,
  aplusLogoColor,
  loginImage,
  lockIcon,
  personIcon,
};
