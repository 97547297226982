import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { ItemTable, TooltipIconButton } from "components/common";

const IndividualMerchantList = ({ dataRows, handleDeleteMerchantGroup }) => {
  const columns = [
    { id: "merchantId", label: "Merchant ID", minWidth: 100 },
    { id: "merchantName", label: "Merchant Name", minWidth: 100 },
    {
      id: "id",
      label: "Operation",
      minWidth: 200,
      align: "right",
      format: (value) => (
        <TooltipIconButton
          title="Delete"
          color="error"
          cb={() => handleDeleteMerchantGroup(value)}
        >
          <DeleteIcon fontSize="inherit" />
        </TooltipIconButton>
      ),
    },
  ];

  return (
    <Box>
      <ItemTable columns={columns} dataRows={dataRows} noPagination={true} />
    </Box>
  );
};

export default IndividualMerchantList;
