import { styled } from "@mui/material/styles";
import {
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  List as MuiList,
} from "@mui/material";
import { COLORS, SIZES } from "constants";

export const drawerWidth = 285;

// header style
export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: COLORS.white,
  color: COLORS.charcoalGray,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

// drawer style
export const navbarStyles = {
  drawer: {
    "& .MuiPaper-root": {
      border: 0,
      pl: 1,
      pr: 0.3,
    },
  },
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const List = styled(MuiList)({
  ".MuiListItemButton-root": {
    paddingLeft: SIZES.h4,
    marginBottom: SIZES.base,
    "&:hover": {
      backgroundColor: COLORS.bgColor,
      borderRadius: SIZES.base,
      color: COLORS.secondary,
    },
  },
  "& .Mui-selected": {
    backgroundColor: `${COLORS.bgColor} !important`,
    borderRadius: SIZES.base,
    color: COLORS.secondary,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: SIZES.h4,
  },
  "& .MuiSvgIcon-root": {
    fontSize: SIZES.body2,
    color: `${COLORS.secondary} !important`,
  },
  "& .MuiListItemText-primary": {
    fontSize: SIZES.body3,
    fontWeight: "medium",
  },
  "& .MuiListItemText-secondary": {
    color: COLORS.charcoalGray,
    fontSize: SIZES.body4,
    fontWeight: "bold",
  },
});
