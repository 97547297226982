import { REQUEST_AUTH_SUCCESS } from "actions/types";

export default (store) => (next) => (action) => {
  if (action?.type === REQUEST_AUTH_SUCCESS && action?.payload?.token) {
    localStorage.setItem("token", action?.payload?.token);
    next(action);
  } else {
    next(action);
  }
};
