import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const HeaderBodyOne = ({ title }) => {
  return (
    <Typography variant="body1" gutterBottom sx={{ my: 2 }}>
      {title}
    </Typography>
  );
};

HeaderBodyOne.propTypes = {
  title: PropTypes.string.isRequired,
};

export default HeaderBodyOne;
