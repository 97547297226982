import { Player } from "@lottiefiles/react-lottie-player";
import NotFoundAnimation from "components/common/Spinner/LottieAnimation/bot-error-404.json";

const NotFoundPageAnimation = () => {
  return (
    <Player
      autoplay
      loop
      src={NotFoundAnimation}
      style={{ height: "80%", width: "80%" }}
    />
  );
};

export default NotFoundPageAnimation;
