import { Box, Paper } from "@mui/material";
import PropTypes from "prop-types";

const SectionLayout = ({ children }) => {
  return (
    <Box>
      <Paper variant="outlined" sx={{ p: 2, my: 1 }}>
        {children}
      </Paper>
    </Box>
  );
};

SectionLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionLayout;
