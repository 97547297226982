import { Controller } from "react-hook-form";
import {
  Switch,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

const SwitchInput = ({ label, name, control }) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <FormControl margin="normal" error={error ? true : false}>
          <FormControlLabel
            control={<Switch id={name} {...field} checked={field.value} />}
            label={label}
          />

          <FormHelperText error={error ? true : false}>
            {error ? error.message : ""}
          </FormHelperText>
        </FormControl>
      )}
      name={name}
      control={control}
    />
  );
};

export default SwitchInput;
