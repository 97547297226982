import { Chip, CircularProgress } from "@mui/material";

const CommonChip = ({ size, color, label, chipSize }) => {
  return (
    <Chip
      icon={<CircularProgress size={size ?? "20"} />}
      label={label}
      size={chipSize ?? "large"}
      variant="outlined"
      color={color ? color : "default"}
    />
  );
};

export default CommonChip;
