import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { requestConsumers } from "actions";
import { CommonDialog, CheckboxTable, LoadingSpinner } from "components/common";
import { pgSize, pgNo } from "constants";
import Search from "./Search";

const columns = [
  {
    id: "consumerName",
    label: "Consumer Name",
    minWidth: 300,
  },
  { id: "phone", label: "Mobile Number", minWidth: 250 },
];

const ConsumerPopup = ({
  open,
  targetConsumers,
  handleDialogBox,
  handleValue,
}) => {
  const { consumerLoading, consumers } = useSelector(
    (state) => state.PopupData,
  );
  const [searchAll, setSearchAll] = useState(true);
  const dispatch = useDispatch();

  const defaultValues = {
    callingCode: "95",
    phoneNumber: "",
    consumerName: "",
    status: 1,
  };

  const validationSchema = yup.object({
    consumerName: yup.string().trim(),
    phoneNumber: yup.string().trim(),
  });

  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    requestGetConsumers(pgSize, pgNo);
  }, [searchAll]);

  const requestGetConsumers = (pageSize, pageNo) => {
    if (searchAll) {
      dispatch(
        requestConsumers(
          {
            callingCode: "",
            phoneNumber: "",
            consumerName: "",
            status: 1,
          },
          {
            pageSize,
            pageNo,
          },
        ),
      );

      reset();
    } else {
      dispatch(
        requestConsumers(
          {
            callingCode: watch("callingCode"),
            phoneNumber: watch("phoneNumber"),
            consumerName: watch("consumerName"),
            status: watch("status"),
          },
          {
            pageSize,
            pageNo,
          },
        ),
      );
    }
  };

  const getDataRows = () => {
    const data = consumers?.data?.map((consumer) => ({
      id: consumer.consumerId,
      consumerName: consumer.consumerName,
      phone: `+${consumer.callingCode} ${consumer.phone}`,
    }));

    return data ? { ...consumers, data } : null;
  };

  return (
    <CommonDialog
      maxWidth="md"
      open={open}
      handleDialogBox={handleDialogBox}
      dialogTitle="Consumers"
    >
      <Box>
        {consumerLoading && <LoadingSpinner />}

        <Search
          control={control}
          handleSubmit={handleSubmit}
          setSearchAll={setSearchAll}
        />

        <CheckboxTable
          columns={columns}
          dataRows={getDataRows()}
          targetValues={targetConsumers}
          handleValueList={(status, value) => {
            handleValue(status, value);
          }}
          requestDataByPageNo={requestGetConsumers}
        />
      </Box>
    </CommonDialog>
  );
};

export default ConsumerPopup;
