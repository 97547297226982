import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { COLORS } from "constants";

const TooltipIconButton = ({ children, title, cb }) => {
  return (
    <Tooltip title={title}>
      <IconButton sx={{ color: COLORS.secondary }} onClick={cb}>
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default TooltipIconButton;
