import { Grid, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { requestMerchants } from "actions";
import FormControl from "components/common/FormHelper/FormControl";
import { CommonContainedButton } from "components/common";
import { pgSize, pgNo } from "constants";

const Search = ({ control, handleSubmit, setSearchAll }) => {
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setSearchAll(false);
    dispatch(requestMerchants(data, { pageSize: pgSize, pageNo: pgNo }));
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              type="text"
              label="Merchant ID"
              name="merchantId"
              control={control}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl
              type="text"
              label="Merchant Name"
              name="merchantName"
              control={control}
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            direction: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CommonContainedButton
            btnText="All"
            size="small"
            cb={() => setSearchAll(true)}
          />

          <CommonContainedButton btnText="Search" size="small" />
        </Grid>
      </form>
    </Box>
  );
};

export default Search;
