import React from "react";
import { ContainedButton } from "components/common/Button/style";
import { COLORS } from "constants";

const CommonContainedButton = ({
  btnText,
  fullWidth,
  size,
  bgColor,
  startIcon,
  endIcon,
  disabled,
  variant,
  cb,
}) => {
  return (
    <>
      <ContainedButton
        sx={{
          backgroundColor: bgColor ? bgColor : COLORS.secondary,
          mr: 1,
        }}
        disabled={disabled ? true : false}
        variant={variant ? variant : "contained"}
        fullWidth={fullWidth ?? false}
        size={size ?? "medium"}
        startIcon={startIcon ?? ""}
        endIcon={endIcon ?? ""}
        onClick={cb ? cb : undefined}
        type={!cb ? "submit" : undefined}
      >
        {btnText}
      </ContainedButton>
    </>
  );
};

export default CommonContainedButton;
