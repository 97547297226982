export const ISSUER_ISSUERMANAGEMENT = "issuer.issuermanagement";

export const MARKETINGMGMT_USERGROUP = "marketingmgmt.usergroup";
export const MARKETINGMGMT_LIMIT = "marketingmgmt.limit";
export const MARKETINGMGMT_RATE = "marketingmgmt.rate";
export const MARKETINGMGMT_POINT = "marketingmgmt.pointconfig";

export const CAMPAIGNMGMT_PAYMENT = "campaignmgmt.payment";
export const CAMPAIGNMGMT_REFERRAL = "campaignmgmt.referral";
export const CAMPAIGNMGMT_POINT = "campaignmgmt.point";

export const REPORTS_REFERRALCODEDETAILREPORT =
  "reports.referralcodedetailreport";
export const REPORTS_CAMPAIGNTRXREPORT = "reports.campaigntrxreport";
export const REPORTS_REFERRALTRXREPORT = "reports.referraltrxreport";

export const OUTSTANDING_POINT_REPORT = "reports.pointreport";

export const SYSTEM_AUDITLOGS = "system.auditlogs";
export const SYSTEM_REGISTER = "system.userregister";
