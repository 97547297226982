import {
  REQUEST_AUTH,
  REQUEST_AUTH_SUCCESS,
  REQUEST_AUTH_ERROR,
  REQUEST_PERMISSIONS_BUTTON,
  REQUEST_PERMISSIONS_BUTTON_SUCCESS,
  REQUEST_PERMISSIONS_BUTTON_ERROR,
  REQUEST_LOGOUT,
  REQUEST_LOGOUT_SUCCESS,
  REQUEST_LOGOUT_ERROR,
} from "actions/types";

const initialState = {
  loading: false,
  error: null,
  auth: null,
  permissionsLoading: false,
  permissionsError: null,
  permissions: null,
  logoutLoading: false,
  logoutError: null,
  logoutStatus: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_AUTH:
      return { ...state, loading: true, error: null };
    case REQUEST_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        auth: action.payload,
      };
    case REQUEST_AUTH_ERROR:
      return { ...state, loading: false, error: action.payload };

    case REQUEST_PERMISSIONS_BUTTON:
      return { ...state, permissionsLoading: true, permissionsError: null };
    case REQUEST_PERMISSIONS_BUTTON_SUCCESS:
      return {
        ...state,
        permissionsLoading: false,
        permissionsError: null,
        permissions: action.payload.data,
      };
    case REQUEST_PERMISSIONS_BUTTON_ERROR:
      return {
        ...state,
        permissionsLoading: false,
        permissionsError: action.payload,
      };

    case REQUEST_LOGOUT:
      return { ...state, logoutLoading: true, logoutError: null };
    case REQUEST_LOGOUT_SUCCESS:
      return {
        ...state,
        logoutLoading: false,
        logoutError: null,
        logoutStatus: action.payload.code === "0" && true,
        auth: action.payload.code === "0" && null,
      };
    case REQUEST_LOGOUT_ERROR:
      return { ...state, logoutLoading: false, logoutError: action.payload };
    default:
      return state;
  }
};
