import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { drawerWidth, navbarStyles, DrawerHeader, Drawer, List } from "./style";
import { navBarItems, IMAGES } from "constants";
import HeaderBar from "./HeaderBar";
import { useGetModulePermission, useGetMenuPermission } from "hooks";

export default function LeftSideBarDrawer() {
  const [open, setOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(null);
  const getPermissionModule = useGetModulePermission();
  const getPermissionMenu = useGetMenuPermission();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const toggleDrawer = () => {
    open && setOpenCollapse(null);
    setOpen(!open);
  };

  const handleCollapse = (currentMenu) => {
    !open && setOpen(true);

    openCollapse === currentMenu
      ? setOpenCollapse(null)
      : setOpenCollapse(currentMenu);
  };

  return (
    <>
      <HeaderBar
        open={open}
        drawerWidth={drawerWidth}
        toggleDrawer={toggleDrawer}
      />

      <Drawer variant="permanent" open={open} sx={navbarStyles.drawer}>
        <DrawerHeader>
          <Toolbar onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
            <img src={IMAGES.aplusLogoColor} width="10%" />
            <Typography
              variant="subtitle1"
              display="inline"
              sx={{ ml: 1, fontWeight: 600 }}
            >
              Marketing Platform
            </Typography>
          </Toolbar>
        </DrawerHeader>

        <List component="nav">
          {navBarItems?.map((item, index) => (
            <React.Fragment key={index}>
              {!item.subMenu?.length ? (
                <Tooltip
                  key={index}
                  title={!open ? item.tooltip : ""}
                  placement="right"
                >
                  <ListItemButton
                    selected={pathname === item.route ? true : false}
                    onClick={() => navigate(`${item.route}`)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </Tooltip>
              ) : (
                getPermissionModule(item.label) && (
                  <Tooltip
                    key={index}
                    title={!open ? item.tooltip : ""}
                    placement="right"
                  >
                    <ListItemButton
                      selected={pathname === item.route ? true : false}
                      onClick={() => {
                        item.subMenu
                          ? handleCollapse(item.label)
                          : navigate(`${item.route}`);
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.label} />
                      {item.subMenu &&
                        (openCollapse === item.label ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        ))}
                    </ListItemButton>
                  </Tooltip>
                )
              )}
              {item.subMenu && (
                <Collapse
                  in={openCollapse === item.label ? true : false}
                  timeout="auto"
                  unmountOnExit
                >
                  {item.subMenu.map(
                    (menu, index) =>
                      getPermissionMenu(item.label, menu.code) && (
                        <List key={index} component="div" disablePadding>
                          <ListItemButton
                            selected={
                              pathname.includes(menu.route) ? true : false
                            }
                            onClick={() => navigate(`${menu.route}`)}
                          >
                            <ListItemIcon sx={{ ml: 2 }}>
                              {menu.icon}
                            </ListItemIcon>
                            <ListItemText secondary={menu.label} />
                          </ListItemButton>
                        </List>
                      ),
                  )}
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </>
  );
}
