import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PropTypes from "prop-types";
import { CustomTypography } from "./style";

const BreadcrumbWithIcon = ({
  parentMenu,
  childMenu,
  secondChildMenu,
  parentIcon,
  childIcon,
}) => {
  const navigate = useNavigate();

  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <CustomTypography
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
        >
          {parentIcon}
          {parentMenu}
        </CustomTypography>

        <CustomTypography
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          color="text.primary"
          onClick={() => {
            secondChildMenu && navigate(-1);
          }}
        >
          {childIcon}
          {childMenu}
        </CustomTypography>

        {secondChildMenu && (
          <CustomTypography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            {secondChildMenu}
          </CustomTypography>
        )}
      </Breadcrumbs>
    </div>
  );
};

BreadcrumbWithIcon.propTypes = {
  parentMenu: PropTypes.string.isRequired,
  childMenu: PropTypes.string.isRequired,
  parentIcon: PropTypes.element.isRequired,
  childIcon: PropTypes.element.isRequired,
};

export default BreadcrumbWithIcon;
