export const REQUEST_AUTH = "REQUEST_AUTH";
export const REQUEST_AUTH_SUCCESS = "REQUEST_AUTH_SUCCESS";
export const REQUEST_AUTH_ERROR = "REQUEST_AUTH_ERROR";
export const REQUEST_PERMISSIONS_BUTTON = "REQUEST_PERMISSIONS_BUTTON";
export const REQUEST_PERMISSIONS_BUTTON_SUCCESS =
  "REQUEST_PERMISSIONS_BUTTON_SUCCESS";
export const REQUEST_PERMISSIONS_BUTTON_ERROR =
  "REQUEST_PERMISSIONS_BUTTON_ERROR";
export const REQUEST_LOGOUT = "REQUEST_LOGOUT";
export const REQUEST_LOGOUT_SUCCESS = "REQUEST_LOGOUT_SUCCESS";
export const REQUEST_LOGOUT_ERROR = "REQUEST_LOGOUT_ERROR";

export const REQUEST_ISSUERS = "REQUEST_ISSUERS";
export const REQUEST_ISSUERS_SUCCESS = "REQUEST_ISSUERS_SUCCESS";
export const REQUEST_ISSUERS_ERROR = "REQUEST_ISSUERS_ERROR";

export const REQUEST_MERCHANTS = "REQUEST_MERCHANTS";
export const REQUEST_MERCHANTS_SUCCESS = "REQUEST_MERCHANTS_SUCCESS";
export const REQUEST_MERCHANTS_ERROR = "REQUEST_MERCHANTS_ERROR";

export const REQUEST_ALL_MERCHANTS = "REQUEST_ALL_MERCHANTS";
export const REQUEST_ALL_MERCHANTS_SUCCESS = "REQUEST_ALL_MERCHANTS_SUCCESS";
export const REQUEST_ALL_MERCHANTS_ERROR = "REQUEST_ALL_MERCHANTS_ERROR";

export const REQUEST_CONSUMERS = "REQUEST_CONSUMERS";
export const REQUEST_CONSUMERS_SUCCESS = "REQUEST_CONSUMERS_SUCCESS";
export const REQUEST_CONSUMERS_ERROR = "REQUEST_CONSUMERS_ERROR";

export const REQUEST_ALL_CONSUMERS = "REQUEST_ALL_CONSUMERS";
export const REQUEST_ALL_CONSUMERS_SUCCESS = "REQUEST_ALL_CONSUMERS_SUCCESS";
export const REQUEST_ALL_CONSUMERS_ERROR = "REQUEST_ALL_CONSUMERS_ERROR";

export const REQUEST_ISSUER_MANAGEMENT = "REQUEST_ISSUER_MANAGEMENT";
export const REQUEST_ISSUER_MANAGEMENT_SUCCESS =
  "REQUEST_ISSUER_MANAGEMENT_SUCCESS";
export const REQUEST_ISSUER_MANAGEMENT_ERROR =
  "REQUEST_ISSUER_MANAGEMENT_ERROR";
export const REQUEST_ISSUER_MANAGEMENT_DETAIL =
  "REQUEST_ISSUER_MANAGEMENT_DETAIL";
export const REQUEST_ISSUER_MANAGEMENT_DETAIL_SUCCESS =
  "REQUEST_ISSUER_MANAGEMENT_DETAIL_SUCCESS";
export const REQUEST_ISSUER_MANAGEMENT_DETAIL_ERROR =
  "REQUEST_ISSUER_MANAGEMENT_DETAIL_ERROR";
export const REQUEST_POST_ISSUER_MANAGEMENT = "REQUEST_POST_ISSUER_MANAGEMENT";
export const REQUEST_POST_ISSUER_MANAGEMENT_SUCCESS =
  "REQUEST_POST_ISSUER_MANAGEMENT_SUCCESS";
export const REQUEST_POST_ISSUER_MANAGEMENT_ERROR =
  "REQUEST_POST_ISSUER_MANAGEMENT_ERROR";
export const REQUEST_POST_ISSUER_MANAGEMENT_UPDATE =
  "REQUEST_POST_ISSUER_MANAGEMENT_UPDATE";
export const REQUEST_POST_ISSUER_MANAGEMENT_UPDATE_SUCCESS =
  "REQUEST_POST_ISSUER_MANAGEMENT_UPDATE_SUCCESS";
export const REQUEST_POST_ISSUER_MANAGEMENT_UPDATE_ERROR =
  "REQUEST_POST_ISSUER_MANAGEMENT_UPDATE_ERROR";
export const REQUEST_POST_ISSUER_MANAGEMENT_DELETE =
  "REQUEST_POST_ISSUER_MANAGEMENT_DELETE";
export const REQUEST_POST_ISSUER_MANAGEMENT_DELETE_SUCCESS =
  "REQUEST_POST_ISSUER_MANAGEMENT_DELETE_SUCCESS";
export const REQUEST_POST_ISSUER_MANAGEMENT_DELETE_ERROR =
  "REQUEST_POST_ISSUER_MANAGEMENT_DELETE_ERROR";

export const REQUEST_POST_SEND_MAIL = "REQUEST_POST_SEND_MAIL";
export const REQUEST_POST_SEND_MAIL_SUCCESS = "REQUEST_POST_SEND_MAIL_SUCCESS";
export const REQUEST_POST_SEND_MAIL_ERROR = "REQUEST_POST_SEND_MAIL_ERROR";

export const REQUEST_LIMIT_MANAGEMENT = "REQUEST_LIMIT_MANAGEMENT";
export const REQUEST_LIMIT_MANAGEMENT_SUCCESS =
  "REQUEST_LIMIT_MANAGEMENT_SUCCESS";
export const REQUEST_LIMIT_MANAGEMENT_ERROR = "REQUEST_LIMIT_MANAGEMENT_ERROR";
export const REQUEST_LIMIT_MANAGEMENT_DETAIL =
  "REQUEST_LIMIT_MANAGEMENT_DETAIL";
export const REQUEST_LIMIT_MANAGEMENT_DETAIL_SUCCESS =
  "REQUEST_LIMIT_MANAGEMENT_DETAIL_SUCCESS";
export const REQUEST_LIMIT_MANAGEMENT_DETAIL_ERROR =
  "REQUEST_LIMIT_MANAGEMENT_DETAIL_ERROR";
export const REQUEST_POST_LIMIT_MANAGEMENT = "REQUEST_POST_LIMIT_MANAGEMENT";
export const REQUEST_POST_LIMIT_MANAGEMENT_SUCCESS =
  "REQUEST_POST_LIMIT_MANAGEMENT_SUCCESS";
export const REQUEST_POST_LIMIT_MANAGEMENT_ERROR =
  "REQUEST_POST_LIMIT_MANAGEMENT_ERROR";
export const REQUEST_POST_LIMIT_MANAGEMENT_UPDATE =
  "REQUEST_POST_LIMIT_MANAGEMENT_UPDATE";
export const REQUEST_POST_LIMIT_MANAGEMENT_UPDATE_SUCCESS =
  "REQUEST_POST_LIMIT_MANAGEMENT_UPDATE_SUCCESS";
export const REQUEST_POST_LIMIT_MANAGEMENT_UPDATE_ERROR =
  "REQUEST_POST_LIMIT_MANAGEMENT_UPDATE_ERROR";
export const REQUEST_POST_LIMIT_MANAGEMENT_DELETE =
  "REQUEST_POST_LIMIT_MANAGEMENT_DELETE";
export const REQUEST_POST_LIMIT_MANAGEMENT_DELETE_SUCCESS =
  "REQUEST_POST_LIMIT_MANAGEMENT_DELETE_SUCCESS";
export const REQUEST_POST_LIMIT_MANAGEMENT_DELETE_ERROR =
  "REQUEST_POST_LIMIT_MANAGEMENT_DELETE_ERROR";

export const REQUEST_RATE = "REQUEST_RATE";
export const REQUEST_RATE_SUCCESS = "REQUEST_RATE_SUCCESS";
export const REQUEST_RATE_ERROR = "REQUEST_RATE_ERROR";
export const REQUEST_RATE_DETAIL = "REQUEST_RATE_DETAIL";
export const REQUEST_RATE_DETAIL_SUCCESS = "REQUEST_RATE_DETAIL_SUCCESS";
export const REQUEST_RATE_DETAIL_ERROR = "REQUEST_RATE_DETAIL_ERROR";
export const REQUEST_RATE_TYPE = "REQUEST_RATE_TYPE";
export const REQUEST_RATE_TYPE_SUCCESS = "REQUEST_RATE_TYPE_SUCCESS";
export const REQUEST_RATE_TYPE_ERROR = "REQUEST_RATE_TYPE_ERROR";
export const REQUEST_POST_RATE = "REQUEST_POST_RATE";
export const REQUEST_POST_RATE_SUCCESS = "REQUEST_POST_RATE_SUCCESS";
export const REQUEST_POST_RATE_ERROR = "REQUEST_POST_RATE_ERROR";
export const REQUEST_POST_RATE_UPDATE = "REQUEST_POST_RATE_UPDATE";
export const REQUEST_POST_RATE_UPDATE_SUCCESS =
  "REQUEST_POST_RATE_UPDATE_SUCCESS";
export const REQUEST_POST_RATE_UPDATE_ERROR = "REQUEST_POST_RATE_UPDATE_ERROR";
export const REQUEST_RATE_DELETE = "REQUEST_RATE_DELETE";
export const REQUEST_RATE_DELETE_SUCCESS = "REQUEST_RATE_DELETE_SUCCESS";
export const REQUEST_RATE_DELETE_ERROR = "REQUEST_RATE_DELETE_ERROR";

export const REQUEST_TRANSACTION_TYPE = "REQUEST_TRANSACTION_TYPE";
export const REQUEST_TRANSACTION_TYPE_SUCCESS =
  "REQUEST_TRANSACTION_TYPE_SUCCESS";
export const REQUEST_TRANSACTION_TYPE_ERROR = "REQUEST_TRANSACTION_TYPE_ERROR";

export const REQUEST_POINT_CONFIG = "REQUEST_POINT_CONFIG";
export const REQUEST_POINT_CONFIG_SUCCESS = "REQUEST_POINT_CONFIG_SUCCESS";
export const REQUEST_POINT_CONFIG_ERROR = "REQUEST_POINT_CONFIG_ERROR";

export const REQUEST_POINT = "REQUEST_POINT";
export const REQUEST_POINT_SUCCESS = "REQUEST_POINT_SUCCESS";
export const REQUEST_POINT_ERROR = "REQUEST_POINT_ERROR";
export const REQUEST_POINT_DETAIL = "REQUEST_POINT_DETAIL";
export const REQUEST_POINT_DETAIL_SUCCESS = "REQUEST_POINT_DETAIL_SUCCESS";
export const REQUEST_POINT_DETAIL_ERROR = "REQUEST_POINT_DETAIL_ERROR";
export const REQUEST_POST_POINT = "REQUEST_POST_POINT";
export const REQUEST_POST_POINT_SUCCESS = "REQUEST_POST_POINT_SUCCESS";
export const REQUEST_POST_POINT_ERROR = "REQUEST_POST_POINT_ERROR";
export const REQUEST_POST_POINT_UPDATE = "REQUEST_POST_POINT_UPDATE";
export const REQUEST_POST_POINT_UPDATE_SUCCESS =
  "REQUEST_POST_POINT_UPDATE_SUCCESS";
export const REQUEST_POST_POINT_UPDATE_ERROR =
  "REQUEST_POST_POINT_UPDATE_ERROR";
export const REQUEST_POINT_DELETE = "REQUEST_POINT_DELETE";
export const REQUEST_POINT_DELETE_SUCCESS = "REQUEST_POINT_DELETE_SUCCESS";
export const REQUEST_POINT_DELETE_ERROR = "REQUEST_POINT_DELETE_ERROR";

export const REQUEST_POINT_CAMPAIGN = "REQUEST_POINT_CAMPAIGN";
export const REQUEST_POINT_CAMPAIGN_SUCCESS = "REQUEST_POINT_CAMPAIGN_SUCCESS";
export const REQUEST_POINT_CAMPAIGN_ERROR = "REQUEST_POINT_CAMPAIGN_ERROR";
export const REQUEST_POINT_CAMPAIGN_DETAIL = "REQUEST_POINT_CAMPAIGN_DETAIL";
export const REQUEST_POINT_CAMPAIGN_DETAIL_SUCCESS =
  "REQUEST_POINT_CAMPAIGN_DETAIL_SUCCESS";
export const REQUEST_POINT_CAMPAIGN_DETAIL_ERROR =
  "REQUEST_POINT_CAMPAIGN_DETAIL_ERROR";
export const REQUEST_POST_POINT_CAMPAIGN = "REQUEST_POST_POINT_CAMPAIGN";
export const REQUEST_POST_POINT_CAMPAIGN_SUCCESS =
  "REQUEST_POST_POINT_CAMPAIGN_SUCCESS";
export const REQUEST_POST_POINT_CAMPAIGN_ERROR =
  "REQUEST_POST_POINT_CAMPAIGN_ERROR";
export const REQUEST_POST_POINT_CAMPAIGN_UPDATE =
  " REQUEST_POST_POINT_CAMPAIGN_UPDATE";
export const REQUEST_POST_POINT_CAMPAIGN_UPDATE_SUCCESS =
  "REQUEST_POST_POINT_CAMPAIGN_UPDATE_SUCCESS";
export const REQUEST_POST_POINT_CAMPAIGN_UPDATE_ERROR =
  "REQUEST_POST_POINT_CAMPAIGN_UPDATE_ERROR";
export const REQUEST_POINT_CAMPAIGN_DELETE = "REQUEST_POINT_CAMPAIGN_DELETE";
export const REQUEST_POINT_CAMPAIGN_DELETE_SUCCESS =
  "REQUEST_POINT_CAMPAIGN_DELETE_SUCCESS";
export const REQUEST_POINT_CAMPAIGN_DELETE_ERROR =
  "REQUEST_POINT_CAMPAIGN_DELETE_ERROR";
export const REQUEST_POINT_CAMPAIGN_PAUSE = "REQUEST_POINT_CAMPAIGN_PAUSE";
export const REQUEST_POINT_CAMPAIGN_PAUSE_SUCCESS =
  "REQUEST_POINT_CAMPAIGN_PAUSE_SUCCESS";
export const REQUEST_POINT_CAMPAIGN_PAUSE_ERROR =
  "REQUEST_POINT_CAMPAIGN_PAUSE_ERROR";
export const REQUEST_POINT_CAMPAIGN_PLAY = "REQUEST_POINT_CAMPAIGN_PLAY";
export const REQUEST_POINT_CAMPAIGN_PLAY_SUCCESS =
  "REQUEST_POINT_CAMPAIGN_PLAY_SUCCESS";
export const REQUEST_POINT_CAMPAIGN_PLAY_ERROR =
  "REQUEST_POINT_CAMPAIGN_PLAY_ERROR";
export const REQUEST_POINT_CAMPAIGN_STOP = "REQUEST_POINT_CAMPAIGN_STOP";
export const REQUEST_POINT_CAMPAIGN_STOP_SUCCESS =
  "REQUEST_POINT_CAMPAIGN_STOP_SUCCESS";
export const REQUEST_POINT_CAMPAIGN_STOP_ERROR =
  "REQUEST_POINT_CAMPAIGN_STOP_ERROR";

export const REQUEST_USER_GROUP = "REQUEST_USER_GROUP";
export const REQUEST_USER_GROUP_SUCCESS = "REQUEST_USER_GROUP_SUCCESS";
export const REQUEST_USER_GROUP_ERROR = "REQUEST_USER_GROUP_ERROR";

export const REQUEST_POST_CREATE_USER_GROUP = "REQUEST_POST_CREATE_USER_GROUP";
export const REQUEST_POST_CREATE_USER_GROUP_SUCCESS =
  "REQUEST_POST_CREATE_USER_GROUP_SUCCESS";
export const REQUEST_POST_CREATE_USER_GROUP_ERROR =
  "REQUEST_POST_CREATE_USER_GROUP_ERROR";

export const REQUEST_POST_USER_GROUP_ATTACHMENT =
  "REQUEST_POST_USER_GROUP_ATTACHMENT";
export const REQUEST_POST_USER_GROUP_ATTACHMENT_SUCCESS =
  "REQUEST_POST_USER_GROUP_ATTACHMENT_SUCCESS";
export const REQUEST_POST_USER_GROUP_ATTACHMENT_ERROR =
  "REQUEST_POST_USER_GROUP_ATTACHMENT_ERROR";

export const REQUEST_USER_GROUP_IMPORT = "REQUEST_USER_GROUP_IMPORT";
export const REQUEST_USER_GROUP_IMPORT_SUCCESS =
  "REQUEST_USER_GROUP_IMPORT_SUCCESS";
export const REQUEST_USER_GROUP_IMPORT_ERROR =
  "REQUEST_USER_GROUP_IMPORT_ERROR";

export const REQUEST_USER_GROUP_IMPORT_UPDATE =
  "REQUEST_USER_GROUP_IMPORT_UPDATE";
export const REQUEST_USER_GROUP_IMPORT_UPDATE_SUCCESS =
  "REQUEST_USER_GROUP_IMPORT_UPDATE_SUCCESS";
export const REQUEST_USER_GROUP_IMPORT_UPDATE_ERROR =
  "REQUEST_USER_GROUP_IMPORT_UPDATE_ERROR";

export const REQUEST_USER_GROUP_IMPORT_DETAIL =
  "REQUEST_USER_GROUP_IMPORT_DETAIL";
export const REQUEST_USER_GROUP_IMPORT_DETAIL_SUCCESS =
  "REQUEST_USER_GROUP_IMPORT_DETAIL_SUCCESS";
export const REQUEST_USER_GROUP_IMPORT_DETAIL_ERROR =
  "REQUEST_USER_GROUP_IMPORT_DETAIL_ERROR";

export const REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD =
  "REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD";
export const REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD_SUCCESS =
  "REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD_SUCCESS";
export const REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD_ERROR =
  "REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD_ERROR";

export const REQUEST_MERCHANT_USER_GROUP = "REQUEST_MERCHANT_USER_GROUP";
export const REQUEST_MERCHANT_USER_GROUP_SUCCESS =
  "REQUEST_MERCHANT_USER_GROUP_SUCCESS";
export const REQUEST_MERCHANT_USER_GROUP_ERROR =
  "REQUEST_MERCHANT_USER_GROUP_ERROR";

export const REQUEST_CONSUMER_USER_GROUP = "REQUEST_CONSUMER_USER_GROUP";
export const REQUEST_CONSUMER_USER_GROUP_SUCCESS =
  "REQUEST_CONSUMER_USER_GROUP_SUCCESS";
export const REQUEST_CONSUMER_USER_GROUP_ERROR =
  "REQUEST_CONSUMER_USER_GROUP_ERROR";

export const REQUEST_USER_GROUP_DETAIL = "REQUEST_USER_GROUP_DETAIL";
export const REQUEST_USER_GROUP_DETAIL_SUCCESS =
  "REQUEST_USER_GROUP_DETAIL_SUCCESS";
export const REQUEST_USER_GROUP_DETAIL_ERROR =
  "REQUEST_USER_GROUP_DETAIL_ERROR";
export const REQUEST_POST_USER_GROUP = "REQUEST_POST_USER_GROUP";
export const REQUEST_POST_USER_GROUP_SUCCESS =
  "REQUEST_POST_USER_GROUP_SUCCESS";
export const REQUEST_POST_USER_GROUP_ERROR = "REQUEST_POST_USER_GROUP_ERROR";
export const REQUEST_POST_USER_GROUP_UPDATE = "REQUEST_POST_USER_GROUP_UPDATE";
export const REQUEST_POST_USER_GROUP_UPDATE_SUCCESS =
  "REQUEST_POST_USER_GROUP_UPDATE_SUCCESS";
export const REQUEST_POST_USER_GROUP_UPDATE_ERROR =
  "REQUEST_POST_USER_GROUP_UPDATE_ERROR";
export const REQUEST_POST_USER_GROUP_DELETE = "REQUEST_POST_USER_GROUP_DELETE";
export const REQUEST_POST_USER_GROUP_DELETE_SUCCESS =
  "REQUEST_POST_USER_GROUP_DELETE_SUCCESS";
export const REQUEST_POST_USER_GROUP_DELETE_ERROR =
  "REQUEST_POST_USER_GROUP_DELETE_ERROR";

export const REQUEST_LIMIT = "REQUEST_LIMIT";
export const REQUEST_LIMIT_SUCCESS = "REQUEST_LIMIT_SUCCESS";
export const REQUEST_LIMIT_ERROR = "REQUEST_LIMIT_ERROR";

export const REQUEST_PAYMENT_CAMPAIGN = "REQUEST_PAYMENT_CAMPAIGN";
export const REQUEST_PAYMENT_CAMPAIGN_SUCCESS =
  "REQUEST_PAYMENT_CAMPAIGN_SUCCESS";
export const REQUEST_PAYMENT_CAMPAIGN_ERROR = "REQUEST_PAYMENT_CAMPAIGN_ERROR";
export const REQUEST_PAYMENT_CAMPAIGN_DETAIL =
  "REQUEST_PAYMENT_CAMPAIGN_DETAIL";
export const REQUEST_PAYMENT_CAMPAIGN_DETAIL_SUCCESS =
  "REQUEST_PAYMENT_CAMPAIGN_DETAIL_SUCCESS";
export const REQUEST_PAYMENT_CAMPAIGN_DETAIL_ERROR =
  "REQUEST_PAYMENT_CAMPAIGN_DETAIL_ERROR";
export const REQUEST_POST_PAYMENT_CAMPAIGN = "REQUEST_POST_PAYMENT_CAMPAIGN";
export const REQUEST_POST_PAYMENT_CAMPAIGN_SUCCESS =
  "REQUEST_POST_PAYMENT_CAMPAIGN_SUCCESS";
export const REQUEST_POST_PAYMENT_CAMPAIGN_ERROR =
  "REQUEST_POST_PAYMENT_CAMPAIGN_ERROR";
export const REQUEST_POST_PAYMENT_CAMPAIGN_UPDATE =
  "REQUEST_POST_PAYMENT_CAMPAIGN_UPDATE";
export const REQUEST_POST_PAYMENT_CAMPAIGN_UPDATE_SUCCESS =
  "REQUEST_POST_PAYMENT_CAMPAIGN_UPDATE_SUCCESS";
export const REQUEST_POST_PAYMENT_CAMPAIGN_UPDATE_ERROR =
  "REQUEST_POST_PAYMENT_CAMPAIGN_UPDATE_ERROR";
export const REQUEST_POST_PAYMENT_CAMPAIGN_DELETE =
  "REQUEST_POST_PAYMENT_CAMPAIGN_DELETE";
export const REQUEST_POST_PAYMENT_CAMPAIGN_DELETE_SUCCESS =
  "REQUEST_POST_PAYMENT_CAMPAIGN_DELETE_SUCCESS";
export const REQUEST_POST_PAYMENT_CAMPAIGN_DELETE_ERROR =
  "REQUEST_POST_PAYMENT_CAMPAIGN_DELETE_ERROR";

export const REQUEST_REFERRAL_CAMPAIGN = "REQUEST_REFERRAL_CAMPAIGN";
export const REQUEST_REFERRAL_CAMPAIGN_SUCCESS =
  "REQUEST_REFERRAL_CAMPAIGN_SUCCESS";
export const REQUEST_REFERRAL_CAMPAIGN_ERROR =
  "REQUEST_REFERRAL_CAMPAIGN_ERROR";
export const REQUEST_REFERRAL_CAMPAIGN_DETAIL =
  "REQUEST_REFERRAL_CAMPAIGN_DETAIL";
export const REQUEST_REFERRAL_CAMPAIGN_DETAIL_SUCCESS =
  "REQUEST_REFERRAL_CAMPAIGN_DETAIL_SUCCESS";
export const REQUEST_REFERRAL_CAMPAIGN_DETAIL_ERROR =
  "REQUEST_REFERRAL_CAMPAIGN_DETAIL_ERROR";
export const REQUEST_POST_REFERRAL_CAMPAIGN = "REQUEST_POST_REFERRAL_CAMPAIGN";
export const REQUEST_POST_REFERRAL_CAMPAIGN_SUCCESS =
  "REQUEST_POST_REFERRAL_CAMPAIGN_SUCCESS";
export const REQUEST_POST_REFERRAL_CAMPAIGN_ERROR =
  "REQUEST_POST_REFERRAL_CAMPAIGN_ERROR";
export const REQUEST_POST_REFERRAL_CAMPAIGN_UPDATE =
  "REQUEST_POST_REFERRAL_CAMPAIGN_UPDATE";
export const REQUEST_POST_REFERRAL_CAMPAIGN_UPDATE_SUCCESS =
  "REQUEST_POST_REFERRAL_CAMPAIGN_UPDATE_SUCCESS";
export const REQUEST_POST_REFERRAL_CAMPAIGN_UPDATE_ERROR =
  "REQUEST_POST_REFERRAL_CAMPAIGN_UPDATE_ERROR";
export const REQUEST_POST_REFERRAL_CAMPAIGN_DELETE =
  "REQUEST_POST_REFERRAL_CAMPAIGN_DELETE";
export const REQUEST_POST_REFERRAL_CAMPAIGN_DELETE_SUCCESS =
  "REQUEST_POST_REFERRAL_CAMPAIGN_DELETE_SUCCESS";
export const REQUEST_POST_REFERRAL_CAMPAIGN_DELETE_ERROR =
  "REQUEST_POST_REFERRAL_CAMPAIGN_DELETE_ERROR";

export const REQUEST_POST_CAMPAIGN_PLAY = "REQUEST_POST_CAMPAIGN_PLAY";
export const REQUEST_POST_CAMPAIGN_PLAY_SUCCESS =
  "REQUEST_POST_CAMPAIGN_PLAY_SUCCESS";
export const REQUEST_POST_CAMPAIGN_PLAY_ERROR =
  "REQUEST_POST_CAMPAIGN_PLAY_ERROR";

export const REQUEST_POST_CAMPAIGN_PAUSE = "REQUEST_POST_CAMPAIGN_PAUSE";
export const REQUEST_POST_CAMPAIGN_PAUSE_SUCCESS =
  "REQUEST_POST_CAMPAIGN_PAUSE_SUCCESS";
export const REQUEST_POST_CAMPAIGN_PAUSE_ERROR =
  "REQUEST_POST_CAMPAIGN_PAUSE_ERROR";

export const REQUEST_POST_CAMPAIGN_STOP = "REQUEST_POST_CAMPAIGN_STOP";
export const REQUEST_POST_CAMPAIGN_STOP_SUCCESS =
  "REQUEST_POST_CAMPAIGN_STOP_SUCCESS";
export const REQUEST_POST_CAMPAIGN_STOP_ERROR =
  "REQUEST_POST_CAMPAIGN_STOP_ERROR";

export const REQUEST_REFERRAL_TRX_HISTORY = "REQUEST_REFERRAL_TRX_HISTORY";
export const REQUEST_REFERRAL_TRX_HISTORY_SUCCESS =
  "REQUEST_REFERRAL_TRX_HISTORY_SUCCESS";
export const REQUEST_REFERRAL_TRX_HISTORY_ERROR =
  "REQUEST_REFERRAL_TRX_HISTORY_ERROR";

export const REQUEST_REFERRAL_CODE_DETAIL_REPORT =
  "REQUEST_REFERRAL_CODE_DETAIL_REPORT";
export const REQUEST_REFERRAL_CODE_DETAIL_REPORT_SUCCESS =
  "REQUEST_REFERRAL_CODE_DETAIL_REPORT_SUCCESS";
export const REQUEST_REFERRAL_CODE_DETAIL_REPORT_ERROR =
  "REQUEST_REFERRAL_CODE_DETAIL_REPORT_ERROR";

export const REQUEST_CAMPAIGN_TRX_HISTORY = "REQUEST_CAMPAIGN_TRX_HISTORY";
export const REQUEST_CAMPAIGN_TRX_HISTORY_SUCCESS =
  "REQUEST_CAMPAIGN_TRX_HISTORY_SUCCESS";
export const REQUEST_CAMPAIGN_TRX_HISTORY_ERROR =
  "REQUEST_CAMPAIGN_TRX_HISTORY_ERROR";

export const REQUEST_ACCOUNT_NUMBER = "REQUEST_ACCOUNT_NUMBER";
export const REQUEST_ACCOUNT_NUMBER_SUCCESS = "REQUEST_ACCOUNT_NUMBER_SUCCESS";
export const REQUEST_ACCOUNT_NUMBER_ERROR = "REQUEST_ACCOUNT_NUMBER_ERROR";

export const REQUEST_AUDIT_LOG = "REQUEST_AUDIT_LOG";
export const REQUEST_AUDIT_LOG_SUCCESS = "REQUEST_AUDIT_LOG_SUCCESS";
export const REQUEST_AUDIT_LOG_ERROR = "REQUEST_AUDIT_LOG_ERROR";

export const REQUEST_OUTSTANDING_REPORT = "REQUEST_OUTSTANDING_REPORT";
export const REQUEST_OUTSTANDING_REPORT_SUCCESS =
  "REQUEST_OUTSTANDING_REPORT_SUCCESS";
export const REQUEST_OUTSTANDING_REPORT_ERROR =
  "REQUEST_OUTSTANDING_REPORT_ERROR";

export const REQUEST_USER_REGISTER = "REQUEST_USER_REGISTER";
export const REQUEST_USER_REGISTER_SUCCESS = "REQUEST_USER_REGISTER_SUCCESS";
export const REQUEST_USER_REGISTER_ERROR = "REQUEST_USER_REGISTER_ERROR";

export const REQUEST_APP_NAME = "REQUEST_APP_NAME";
export const REQUEST_APP_NAME_SUCCESS = "REQUEST_APP_NAME_SUCCESS";
export const REQUEST_APP_NAME_ERROR = "REQUEST_APP_NAME_ERROR";

export const REQUEST_ROLE_NAME = "REQUEST_ROLE_NAME";
export const REQUEST_ROLE_NAME_SUCCESS = "REQUEST_ROLE_NAME_SUCCESS";
export const REQUEST_ROLE_NAME_ERROR = "REQUEST_ROLE_NAME_ERROR";

export const REQUEST_CLEAR_STATE = "REQUEST_CLEAR_STATE";
export const REQUEST_CLEAR_STATE_SUCCESS = "REQUEST_CLEAR_STATE_SUCCESS";
export const REQUEST_CLEAR_STATE_ERROR = "REQUEST_CLEAR_STATE_ERROR";

export const REQUEST_CLEAR_REPORT_DATA = "REQUEST_CLEAR_REPORT_DATA";
export const REQUEST_CLEAR_REPORT_DATA_SUCCESS =
  "REQUEST_CLEAR_REPORT_DATA_SUCCESS";
export const REQUEST_CLEAR_REPORT_DATA_ERROR =
  "REQUEST_CLEAR_REPORT_DATA_ERROR";

export const REQUEST_FILE_UPLOAD_DATA = "REQUEST_FILE_UPLOAD_DATA";
export const REQUEST_FILE_UPLOAD_DATA_SUCCESS =
  "REQUEST_FILE_UPLOAD_DATA_SUCCESS";
export const REQUEST_FILE_UPLOAD_DATA_ERROR = "REQUEST_FILE_UPLOAD_DATA_ERROR";
