import { useSelector } from "react-redux";
import { getModule } from "utils";

export default () => {
  const { permissions } = useSelector((state) => state.auth);

  const getPermissionModule = (moduleName) => {
    const module = getModule(permissions, moduleName);

    const result = module?.menus.some((res) => res.checkFlag === true);

    return result;
  };

  return getPermissionModule;
};
