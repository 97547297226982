import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { requestMerchantUserGroup } from "actions";
import { CommonDialog, ClickableTable } from "components/common";
import { pgSize, pgNo } from "constants";

const columns = [
  { id: "groupId", label: "Group No.", minWidth: 350 },
  { id: "groupName", label: "Group Name", minWidth: 350 },
];

const MerchantGroupClickablePopup = ({
  open,
  handleDialogBox,
  handleValue,
}) => {
  const { userGroupLoading, merchantUserGroups } = useSelector(
    (state) => state.PopupData,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    requestGetMerchantUserGroup(pgSize, pgNo);
  }, []);

  const requestGetMerchantUserGroup = (pageSize, pageNo) => {
    dispatch(
      requestMerchantUserGroup(
        {
          groupType: "merchant",
          groupName: "",
          status: 1,
        },
        { pageSize, pageNo },
      ),
    );
  };

  return (
    <CommonDialog
      maxWidth="md"
      open={open}
      handleDialogBox={handleDialogBox}
      dialogTitle="Merchant Group"
    >
      <Box>
        <ClickableTable
          columns={columns}
          dataRows={merchantUserGroups}
          cb={handleValue}
          handleDialogBox={handleDialogBox}
          requestDataByPageNo={requestGetMerchantUserGroup}
        />
      </Box>
    </CommonDialog>
  );
};

export default MerchantGroupClickablePopup;
