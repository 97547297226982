import { Grid, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import FormControl from "components/common/FormHelper/FormControl";
import { CommonContainedButton } from "components/common";
import { requestConsumers } from "actions";
import { pgSize, pgNo } from "constants";

const Search = ({ control, handleSubmit, setSearchAll }) => {
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setSearchAll(false);
    dispatch(requestConsumers(data, { pageSize: pgSize, pageNo: pgNo }));
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl
              type="select"
              label="Code"
              name="callingCode"
              control={control}
              options={[
                { id: 1, value: "95", label: "+95" },
                { id: 2, value: "66", label: "+66" },
              ]}
            />
          </Grid>

          <Grid item xs={5}>
            <FormControl
              type="number"
              label="Mobile Number"
              name="phoneNumber"
              control={control}
            />
          </Grid>

          <Grid item xs={5}>
            <FormControl
              type="text"
              label="Consumer Name"
              name="consumerName"
              control={control}
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            direction: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CommonContainedButton
            btnText="All"
            size="small"
            cb={() => setSearchAll(true)}
          />

          <CommonContainedButton btnText="Search" size="small" />
        </Grid>
      </form>
    </Box>
  );
};

export default Search;
