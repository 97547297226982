import { useSelector } from "react-redux";
import { getMenuByCode } from "utils";

export default () => {
  const { permissions } = useSelector((state) => state.auth);

  const getPermissionMenu = (moduleName, menuCode) => {
    const result = getMenuByCode(permissions, moduleName, menuCode);

    return result?.checkFlag;
  };

  return getPermissionMenu;
};
