import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { requestIssuers } from "actions";
import {
  CommonDialog,
  ClickableTable,
  LoadingSpinner,
} from "components/common";
import { pgSize, pgNo } from "constants";
import Search from "./Search";

const columns = [
  { id: "issuerNo", label: "Issuer No.", minWidth: 100 },
  { id: "issuerName", label: "Issuer Name", minWidth: 300 },
  { id: "accountNumber", label: "Account Number", minWidth: 300 },
];

const IssuerPopup = ({ open, handleDialogBox, handleIssuerValue }) => {
  const { loading, issuers } = useSelector((state) => state.PopupData);
  const [searchAll, setSearchAll] = useState(true);
  const dispatch = useDispatch();

  const defaultValues = {
    issuerNo: "",
    issuerName: "",
    issuerStatus: 1,
  };

  const validationSchema = yup.object({
    issuerNo: yup.string().trim(),
    issuerName: yup.string().trim(),
  });

  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    requestGetIssuers(pgSize, pgNo);
  }, [searchAll]);

  const requestGetIssuers = (pageSize, pageNo) => {
    if (searchAll) {
      dispatch(
        requestIssuers(
          {
            issuerNo: "",
            issuerName: "",
            issuerStatus: 1,
          },
          { pageSize, pageNo },
        ),
      );

      reset();
    } else {
      dispatch(
        requestIssuers(
          {
            issuerNo: watch("issuerNo"),
            issuerName: watch("issuerName"),
            issuerStatus: watch("issuerStatus"),
          },
          { pageSize, pageNo },
        ),
      );
    }
  };

  return (
    <CommonDialog
      maxWidth="md"
      open={open}
      handleDialogBox={handleDialogBox}
      dialogTitle="Issuer"
    >
      <Box>
        {loading && <LoadingSpinner />}

        <Search
          control={control}
          handleSubmit={handleSubmit}
          setSearchAll={setSearchAll}
        />

        <ClickableTable
          columns={columns}
          dataRows={issuers}
          cb={handleIssuerValue}
          handleDialogBox={handleDialogBox}
          requestDataByPageNo={requestGetIssuers}
        />
      </Box>
    </CommonDialog>
  );
};

export default IssuerPopup;
