import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { SIZES } from "constants";

export const CustomTypography = styled(Typography)({
  "& .MuiSvgIcon-root": {
    marginRight: SIZES.base,
    fontSize: "inherit",
  },
});
