import {
  REQUEST_REFERRAL_TRX_HISTORY,
  REQUEST_REFERRAL_TRX_HISTORY_SUCCESS,
  REQUEST_REFERRAL_TRX_HISTORY_ERROR,
  REQUEST_REFERRAL_CODE_DETAIL_REPORT,
  REQUEST_REFERRAL_CODE_DETAIL_REPORT_SUCCESS,
  REQUEST_REFERRAL_CODE_DETAIL_REPORT_ERROR,
  REQUEST_CAMPAIGN_TRX_HISTORY,
  REQUEST_CAMPAIGN_TRX_HISTORY_SUCCESS,
  REQUEST_CAMPAIGN_TRX_HISTORY_ERROR,
  REQUEST_AUDIT_LOG,
  REQUEST_AUDIT_LOG_SUCCESS,
  REQUEST_AUDIT_LOG_ERROR,
  REQUEST_CLEAR_REPORT_DATA,
  REQUEST_CLEAR_REPORT_DATA_SUCCESS,
  REQUEST_CLEAR_REPORT_DATA_ERROR,
  REQUEST_OUTSTANDING_REPORT,
  REQUEST_OUTSTANDING_REPORT_SUCCESS,
  REQUEST_OUTSTANDING_REPORT_ERROR,
} from "actions/types";

const initialState = {
  referralTrxLoading: false,
  referralTrxError: null,
  referralTrxHistory: null,
  referralCodeDetailReportLoading: false,
  referralCodeDetailReportError: null,
  referralCodeDetailReport: null,
  campaignTrxLoading: false,
  campaignTrxError: null,
  campaignTrxHistory: null,
  auditLogLoading: false,
  auditLogHistory: null,
  auditLogError: null,
  outstandingReportLoading: false,
  outstandingReportHistory: null,
  outstandingReportError: null,
  clearDataLoading: false,
  clearDataError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_REFERRAL_TRX_HISTORY:
      return {
        ...state,
        referralTrxLoading: true,
        referralTrxError: null,
        referralTrxHistory: null,
      };
    case REQUEST_REFERRAL_TRX_HISTORY_SUCCESS:
      return {
        ...state,
        referralTrxLoading: false,
        referralTrxError: null,
        referralTrxHistory:
          action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_REFERRAL_TRX_HISTORY_ERROR:
      return {
        ...state,
        referralTrxLoading: false,
        referralTrxError: action.payload,
      };

    case REQUEST_REFERRAL_CODE_DETAIL_REPORT:
      return {
        ...state,
        referralCodeDetailReportLoading: true,
        referralCodeDetailReportError: null,
        referralCodeDetailReport: null,
      };
    case REQUEST_REFERRAL_CODE_DETAIL_REPORT_SUCCESS:
      return {
        ...state,
        referralCodeDetailReportLoading: false,
        referralCodeDetailReportError: null,
        referralCodeDetailReport:
          action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_REFERRAL_CODE_DETAIL_REPORT_ERROR:
      return {
        ...state,
        referralCodeDetailReportLoading: false,
        referralCodeDetailReportError: action.payload,
      };

    case REQUEST_CAMPAIGN_TRX_HISTORY:
      return {
        ...state,
        campaignTrxLoading: true,
        campaignTrxError: null,
        campaignTrxHistory: null,
      };
    case REQUEST_CAMPAIGN_TRX_HISTORY_SUCCESS:
      return {
        ...state,
        campaignTrxLoading: false,
        campaignTrxError: null,
        campaignTrxHistory:
          action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_CAMPAIGN_TRX_HISTORY_ERROR:
      return {
        ...state,
        campaignTrxLoading: false,
        campaignTrxError: action.payload,
      };

    case REQUEST_AUDIT_LOG:
      return {
        ...state,
        auditLogLoading: true,
        auditLogError: null,
      };
    case REQUEST_AUDIT_LOG_SUCCESS:
      return {
        ...state,
        auditLogLoading: false,
        auditLogError: null,
        auditLogHistory: action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_AUDIT_LOG_ERROR:
      return {
        ...state,
        auditLogLoading: false,
        auditLogError: action.payload,
      };

    case REQUEST_OUTSTANDING_REPORT:
      return {
        ...state,
        outstandingReportLoading: true,
        outstandingReportError: null,
      };
    case REQUEST_OUTSTANDING_REPORT_SUCCESS:
      return {
        ...state,
        outstandingReportLoading: false,
        outstandingReportError: null,
        outstandingReportHistory:
          action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_OUTSTANDING_REPORT_ERROR:
      return {
        ...state,
        outstandingReportLoading: false,
        outstandingReportError: action.payload,
      };

    case REQUEST_CLEAR_REPORT_DATA:
      return {
        ...state,
        clearDataLoading: true,
        clearDataError: null,
      };
    case REQUEST_CLEAR_REPORT_DATA_SUCCESS:
      return initialState;

    case REQUEST_CLEAR_REPORT_DATA_ERROR:
      return {
        ...state,
        clearDataLoading: false,
        clearDataError: action.payload,
      };

    default:
      return state;
  }
};
