import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";

const TextInput = ({
  label,
  name,
  control,
  multiLine,
  required,
  disabled,
  icon,
}) => (
  <Controller
    render={({ field, fieldState: { error } }) => (
      <TextField
        size="small"
        label={label}
        id={name}
        helperText={error ? error.message : ""}
        error={error ? true : false}
        fullWidth={true}
        margin="normal"
        required={required ? true : false}
        disabled={disabled ?? false}
        multiline={multiLine ? true : false}
        rows={multiLine && 5}
        InputProps={{ endAdornment: icon ?? null, required: false }}
        {...field}
      />
    )}
    name={name}
    control={control}
  />
);

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiLine: PropTypes.bool,
  icon: PropTypes.element,
};

export default TextInput;
