import { useNavigate } from "react-router-dom";
import { Toolbar, Typography, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AppBar } from "./style";
import { CommonIconButton, Noti } from "components/common";
import { profileItems, IMAGES } from "constants";

const HeaderBar = ({ open, toggleDrawer }) => {
  const navigate = useNavigate();

  return (
    <AppBar position="fixed" open={open} elevation={0}>
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
          }}
        >
          <CommonIconButton
            size="large"
            label="open drawer"
            edge="start"
            cb={toggleDrawer}
          >
            <MenuIcon fontSize="inherit" />
          </CommonIconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              ml: 1,
              cursor: "pointer",
              ...(open && { display: "none" }),
            }}
            onClick={() => navigate("/")}
          >
            <img src={IMAGES.aplusLogoColor} width="10%" />
            <Typography variant="h6" display="inline" sx={{ ml: 1 }}>
              Marketing Platform
            </Typography>
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Noti size="large" label="Noti-Profile" menuItems={profileItems}>
              <AccountCircleIcon fontSize="large" />
            </Noti>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBar;
