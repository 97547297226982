import {
  REQUEST_USER_REGISTER,
  REQUEST_USER_REGISTER_SUCCESS,
  REQUEST_USER_REGISTER_ERROR,
} from "actions/types";

const initialState = {
  userRegisterLoading: false,
  userRegisterError: null,
  userRegisterStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_USER_REGISTER:
      return {
        ...state,
        userRegisterLoading: true,
        userRegisterError: null,
        userRegisterStatus: null,
      };

    case REQUEST_USER_REGISTER_SUCCESS:
      return {
        ...state,
        userRegisterLoading: false,
        userRegisterError: null,
        userRegisterStatus: action.payload,
      };

    case REQUEST_USER_REGISTER_ERROR:
      return {
        ...state,
        userRegisterLoading: false,
        userRegisterError: action.payload,
      };
    default:
      return state;
  }
};
