import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { requestPointConfig } from "actions";
import {
  CommonDialog,
  ClickableTable,
  LoadingSpinner,
} from "components/common";
import { pgSize, pgNo } from "constants";
import Search from "./Search";

const columns = [
  { id: "name", label: "Name", minWidth: 200 },
  { id: "amount", label: "Amount", minWidth: 200 },
  { id: "count", label: "Point(s)", minWidth: 200 },
];

const PointConfigPopup = ({ open, handleDialogBox, handleValue }) => {
  const { pointLoading, points } = useSelector((state) => state.PopupData);
  const [searchAll, setSearchAll] = useState(true);
  const dispatch = useDispatch();

  const defaultValues = {
    name: "",
    status: 2,
  };

  const validationSchema = yup.object({
    name: yup.string().trim(),
  });

  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    requestGetPointConfig(pgSize, pgNo);
  }, [searchAll]);

  const requestGetPointConfig = (pageSize, pageNo) => {
    if (searchAll) {
      dispatch(
        requestPointConfig(
          {
            name: "",
            status: 2,
          },
          { pageSize, pageNo },
        ),
      );

      reset();
    } else {
      dispatch(
        requestPointConfig(
          {
            name: watch("name"),
            status: watch("status"),
          },
          { pageSize, pageNo },
        ),
      );
    }
  };

  return (
    <CommonDialog
      maxWidth="md"
      open={open}
      handleDialogBox={handleDialogBox}
      dialogTitle="Point Config"
    >
      <Box>
        {pointLoading && <LoadingSpinner />}

        {/* <Search
          control={control}
          handleSubmit={handleSubmit}
          setSearchAll={setSearchAll}
        /> */}

        <ClickableTable
          columns={columns}
          dataRows={points}
          cb={handleValue}
          handleDialogBox={handleDialogBox}
          requestDataByPageNo={requestGetPointConfig}
        />
      </Box>
    </CommonDialog>
  );
};

export default PointConfigPopup;
