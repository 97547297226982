import React from "react";
import { Controller } from "react-hook-form";
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";

const MultiSelect = ({ label, name, control, options }) => {
  return (
    <Controller
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => (
        <FormControl
          fullWidth
          size="small"
          margin="dense"
          error={error ? true : false}
        >
          <InputLabel id={label}>{label}</InputLabel>
          <Select
            value={value}
            labelId={label}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            multiple
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error ? error.message : ""}</FormHelperText>
        </FormControl>
      )}
      name={name}
      control={control}
    />
  );
};

export default MultiSelect;
