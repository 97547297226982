export const COLORS = {
  bgColor: "#EEEEEE", //"#E3E8E8",
  primary: "#888888", // "#BDB0C7",
  secondary: "#555555", //"#E71BF5",
  secondary2: "#EB80F7",
  secondary3: "#492464",

  black: "#1E1F20",
  white: "#FFFFFF",
  error: "#dd2c00",
  success: "#4caf50",

  lightGray: "#F5F5F6",
  lightGray1: "#F6F6F7",
  lightGray2: "#DCDEE6",
  lightGray3: "#C1C5D2",
  lightGray4: "#A7ABBF",
  lightGray5: "#8C92AC",
  softRed: "#FC8181",
  transparent: "transparent",
  darkgray: "#898C95",
  charcoalGray: "#36454F",
};

export const SIZES = {
  base: 8,
  font: 14,
  radius: 10,
  padding: 12,

  largeTitle: 50,
  h1: 30,
  h2: 22,
  h3: 20,
  h4: 18,
  body1: 30,
  body2: 20,
  body3: 16,
  body4: 14,
  body5: 12,
};

const appTheme = { COLORS, SIZES };

export default appTheme;
