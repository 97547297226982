import { Grid, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import FormControl from "components/common/FormHelper/FormControl";
import { CommonContainedButton } from "components/common";
import { requestIssuers } from "actions";
import { pgSize, pgNo } from "constants";

const Search = ({ control, handleSubmit, setSearchAll }) => {
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setSearchAll(false);
    dispatch(
      requestIssuers(
        { ...data, issuerStatus: 1 },
        { pageSize: pgSize, pageNo: pgNo },
      ),
    );
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              type="text"
              label="Issuer No."
              name="issuerNo"
              control={control}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl
              type="text"
              label="Issuer Name"
              name="issuerName"
              control={control}
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            direction: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CommonContainedButton
            btnText="All"
            size="small"
            cb={() => setSearchAll(true)}
          />

          <CommonContainedButton btnText="Search" size="small" />
        </Grid>
      </form>
    </Box>
  );
};

export default Search;
