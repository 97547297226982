import { Box } from "@mui/material";
import { ItemTable } from "components/common";
import React from "react";

const MerchantErrorList = ({ dataRows }) => {
  const columns = [
    { id: "field1", label: "Merchant ID", minWidth: 100 },
    { id: "field2", label: "Merchant Name", minWidth: 100 },
    { id: "massage", label: "Merchant Error Information", minWidth: 100 },
  ];
  return (
    <Box>
      <ItemTable
        columns={columns}
        dataRows={dataRows}
        noPagination={true}
        errorHeader={true}
      />
    </Box>
  );
};

export default MerchantErrorList;
