import { Player } from "@lottiefiles/react-lottie-player";
import LoadingAnimation from "components/common/Spinner/LottieAnimation/94237-loading.json";

const LoadingSpinner = () => {
  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexWrap: "wrap",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        zIndex: 50,
      }}
    >
      <Player
        autoplay
        loop
        src={LoadingAnimation}
        style={{ height: "60%", width: "60%" }}
      />
    </div>
  );
};

export default LoadingSpinner;
