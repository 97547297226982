import React from "react";
import { Box, FormControl, FormLabel, FormHelperText } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Controller } from "react-hook-form";
import Dropzone from "react-dropzone";
import { SampleImage } from "components/common";

const DropzoneFileUpload = ({ label, name, control }) => {
  return (
    <Controller
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth margin="normal" error={error ? true : false}>
          <FormLabel component="legend" id={label}>
            {label}
          </FormLabel>

          <Dropzone
            onDrop={onChange}
            //maxFiles={2}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                {/* Upload Files */}
                <Box
                  {...getRootProps()}
                  sx={{
                    p: 1,
                    borderRadius: 1,
                    border: "1px dashed #CCC",
                  }}
                >
                  <input {...getInputProps()} />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#bdbdbd",
                      width: "100%",
                      height: 120,
                      typography: "body1",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CloudUploadIcon />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </Box>
                </Box>
              </section>
            )}
          </Dropzone>

          {/* SHOW UPLOADED FILES */}
          <Box sx={{ display: "flex" }}>
            {value &&
              value.map((file) => (
                <Box
                  key={file.name}
                  sx={{
                    minWidth: 100,
                    maxWidth: 100,
                    m: 1,
                  }}
                >
                  <SampleImage imageObj={file} showLoading={true} width={100} />
                </Box>
              ))}
          </Box>

          <FormHelperText error={true}>
            {error ? error.message : ""}
          </FormHelperText>
        </FormControl>
      )}
      name={name}
      control={control}
    />
  );
};

export default DropzoneFileUpload;
