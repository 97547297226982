import { utils, writeFile } from "xlsx";
import dayjs from "dayjs";

export const exportDataToExcel = (data, fileName) => {
  let ws = utils.json_to_sheet(data);
  let wb = utils.book_new();
  utils.book_append_sheet(wb, ws, fileName);

  writeFile(wb, `${fileName} - ${dayjs(new Date()).format("DD-MM-YYYY")}.xlsx`);
};
