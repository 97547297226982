import {
  REQUEST_TRANSACTION_TYPE,
  REQUEST_TRANSACTION_TYPE_SUCCESS,
  REQUEST_TRANSACTION_TYPE_ERROR,
} from "actions/types";

const initialState = {
  loading: false,
  error: null,
  transactionTypes: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_TRANSACTION_TYPE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REQUEST_TRANSACTION_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transactionTypes: action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_TRANSACTION_TYPE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
