import Image from "mui-image";

const SimpleImage = ({ imageObj, imageURL, width }) => {
  return (
    <Image
      src={imageObj ? URL.createObjectURL(imageObj) : imageURL}
      showLoading={true}
      style={{
        margin: 0,
        padding: 0,
        borderRadius: 8,
      }}
      className="mui-image-img"
      width={width}
      height={(width / 3) * 2}
    />
  );
};

export default SimpleImage;
