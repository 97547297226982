export const getModule = (permissions, moduleName) =>
  permissions?.modules.find(
    (module) => module?.moduleName.toLowerCase() === moduleName.toLowerCase(),
  );

export const getMenu = (permissions, moduleName, menuName) => {
  const module = getModule(permissions, moduleName);

  const result = module?.menus?.find(
    (menu) => menu.menuName.toLowerCase() === menuName.toLowerCase(),
  );

  return result;
};

export const getMenuByCode = (permissions, moduleName, menuCode) => {
  const module = getModule(permissions, moduleName);

  const result = module?.menus?.find((menu) => menu.menuCode === menuCode);

  return result;
};

export const getFunctionPermission = (
  permissions,
  moduleName,
  menuName,
  operationButton,
) => {
  const menu = getMenu(permissions, moduleName, menuName);

  const result = menu?.functions?.find(
    (func) => func.functionName.toLowerCase() === operationButton.toLowerCase(),
  );

  return result;
};
