import {
  REQUEST_POINT,
  REQUEST_POINT_SUCCESS,
  REQUEST_POINT_ERROR,
  REQUEST_POINT_DETAIL,
  REQUEST_POINT_DETAIL_SUCCESS,
  REQUEST_POINT_DETAIL_ERROR,
  REQUEST_POST_POINT,
  REQUEST_POST_POINT_SUCCESS,
  REQUEST_POST_POINT_ERROR,
  REQUEST_POST_POINT_UPDATE,
  REQUEST_POST_POINT_UPDATE_SUCCESS,
  REQUEST_POST_POINT_UPDATE_ERROR,
  REQUEST_POINT_DELETE,
  REQUEST_POINT_DELETE_SUCCESS,
  REQUEST_POINT_DELETE_ERROR,
} from "actions/types";

const initialState = {
  loading: false,
  error: null,
  points: null,
  detailLoading: false,
  detailError: true,
  point: null,
  postLoading: false,
  postError: null,
  postStatus: null,
  editLoading: false,
  editError: null,
  editStatus: null,
  deleteLoading: false,
  deleteError: null,
  deleteStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_POINT:
      return {
        ...state,
        loading: true,
        error: null,
        postStatus: null,
        editStatus: null,
        deleteStatus: null,
      };
    case REQUEST_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        points: action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_POINT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case REQUEST_POINT_DETAIL:
      return { ...state, detailLoading: true, detailError: null };
    case REQUEST_POINT_DETAIL_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detailError: null,
        point: action.payload.data,
      };
    case REQUEST_POINT_DETAIL_ERROR:
      return { ...state, detailLoading: false, detailError: action.payload };

    case REQUEST_POST_POINT:
      return { ...state, postLoading: true, postError: null };
    case REQUEST_POST_POINT_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: null,
        postStatus: action.payload,
      };
    case REQUEST_POST_POINT_ERROR:
      return { ...state, postLoading: false, postError: action.payload };

    case REQUEST_POST_POINT_UPDATE:
      return { ...state, editLoading: true, editError: null };
    case REQUEST_POST_POINT_UPDATE_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: null,
        editStatus: action.payload,
      };
    case REQUEST_POST_POINT_UPDATE_ERROR:
      return { ...state, editLoading: false, editError: action.payload };

    case REQUEST_POINT_DELETE:
      return { ...state, deleteLoading: true, deleteError: null };
    case REQUEST_POINT_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: null,
        deleteStatus: action.payload,
      };
    case REQUEST_POINT_DELETE_ERROR:
      return { ...state, deleteLoading: false, deleteError: action.payload };

    default:
      return state;
  }
};
