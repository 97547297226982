import React from "react";
import { Controller } from "react-hook-form";
import {
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from "@mui/material";

const CheckBox = ({ label, name, control, options }) => {
  return (
    <Controller
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth margin="normal" error={error ? true : false}>
          <FormLabel component="legend" id={label}>
            {label}
          </FormLabel>

          <FormGroup row>
            {options.map((option) => (
              <FormControlLabel
                key={option.id}
                label={option.label}
                control={
                  <Checkbox
                    checked={value.includes(option.value)}
                    value={option.value || ""}
                    onChange={(e) => {
                      const currentValue = e.target.value;

                      e.target.checked
                        ? onChange([...value, currentValue])
                        : onChange([
                            ...value.filter((val) => val !== currentValue),
                          ]);
                    }}
                  />
                }
              />
            ))}
          </FormGroup>

          <FormHelperText error={error ? true : false}>
            {error ? error.message : ""}
          </FormHelperText>
        </FormControl>
      )}
      name={name}
      control={control}
    />
  );
};

export default CheckBox;
