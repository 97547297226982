import React, { useState } from "react";
import { Badge } from "@mui/material";
import { CommonIconButton, MenuItems } from "components/common";

const Noti = ({ children, size, label, menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <CommonIconButton size={size} label={label} cb={handleClick}>
        {/* <Badge badgeContent={4} color="error" overlap="circular"> */}
        {children}
        {/* </Badge> */}
      </CommonIconButton>

      <MenuItems
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        menuItems={menuItems}
      />
    </>
  );
};

export default Noti;
