import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@mui/material";

const MenuItems = ({ open, anchorEl, handleClose, menuItems }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      {menuItems.map((menu, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            handleClose();

            menu.route
              ? setTimeout(() => navigate(`${menu.route}`), 0)
              : setTimeout(() => {
                  menu.cb(dispatch, navigate, location);
                }, 0);
          }}
        >
          {menu.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

MenuItems.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
};

export default MenuItems;
