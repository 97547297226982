import { redirect } from "react-router-dom";
import { requestLogout, requestClearAllState } from "actions";
import { decodeToken } from "react-jwt";

export const getAuthToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const getTokenExpire = () => {
  const token = getAuthToken();

  if (token) {
    const decodedToken = decodeToken(token);
    const currentDate = new Date();
    const fiveMinLater = new Date(currentDate.getTime() + 5 * 60 * 1000);
    const fiveMinLaterInSec = Math.floor(fiveMinLater.getTime() / 1000);

    if (fiveMinLaterInSec > decodedToken.exp) {
      return true;
    } else {
      return false;
    }
  }
};

export const getDuration = () => {
  const now = new Date();
  const expireOn = sessionStorage.getItem("expireOn");
  const expire = new Date(expireOn);
  const duration = expire.getTime() - now.getTime();

  return duration;
};

export const checkAuthLoader = () => {
  const token = getAuthToken();

  if (!token) {
    return redirect("login");
  }

  return null;
};

export const logoutHandler = (dispatch, navigate, location) => {
  dispatch(requestClearAllState());
  dispatch(requestLogout());
  localStorage.removeItem("token");

  setTimeout(
    () => navigate("/login", { state: { from: location }, replace: true }),
    0,
  );
};
