import { useSelector } from "react-redux";
import { getFunctionPermission } from "utils";

export default () => {
  const { permissions } = useSelector((state) => state.auth);

  const getPermissionButton = (moduleName, menuName, operationButton) => {
    const result = getFunctionPermission(
      permissions,
      moduleName,
      menuName,
      operationButton
    );

    return result?.checkFlag;
  };

  return getPermissionButton;
};
