import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

const PasswordInput = ({ label, name, control }) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <TextField
          type="password"
          size="small"
          label={label}
          id={name}
          helperText={error ? error.message : ""}
          error={error ? true : false}
          fullWidth={true}
          margin="normal"
          InputProps={{ required: false }}
          {...field}
        />
      )}
      name={name}
      control={control}
    />
  );
};

export default PasswordInput;
