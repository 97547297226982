import { lazy } from "react";

export const NotFound = lazy(() => import("pages/error/NotFound"));
export const UnAuthorize = lazy(() => import("pages/error/UnAuthorize"));

export const Login = lazy(() => import("pages/auth/Login"));

export const Dashboard = lazy(() => import("pages/dashboard"));

export const IssuerMgmt = lazy(() => import("pages/issuers/IssuerMgmt"));
export const IssuerMgmtAdd = lazy(() => import("pages/issuers/IssuerMgmt/Add"));

export const PaymentCampaignMgmt = lazy(() =>
  import("pages/campaignMgmt/PaymentCampaignMgmt"),
);
export const PaymentCampaignMgmtAdd = lazy(() =>
  import("pages/campaignMgmt/PaymentCampaignMgmt/Add"),
);

export const ReferralCampaignMgmt = lazy(() =>
  import("pages/campaignMgmt/ReferralCampaignMgmt"),
);
export const ReferralCampaignMgmtAdd = lazy(() =>
  import("pages/campaignMgmt/ReferralCampaignMgmt/Add"),
);

export const PointCampaignMgmt = lazy(() =>
  import("pages/campaignMgmt/PointCampaignMgmt"),
);
export const PointCampaignMgmtAdd = lazy(() =>
  import("pages/campaignMgmt/PointCampaignMgmt/Add"),
);

export const UserGroup = lazy(() => import("pages/marketingMgmt/UserGroup"));
export const UserGroupAdd = lazy(() =>
  import("pages/marketingMgmt/UserGroup/Add"),
);
export const UserGroupImport = lazy(() =>
  import("pages/marketingMgmt/UserGroup/Import"),
);
export const UserGroupCreate = lazy(() =>
  import("pages/marketingMgmt/UserGroup/Create"),
);

export const Limit = lazy(() => import("pages/marketingMgmt/Limit"));
export const LimitAdd = lazy(() => import("pages/marketingMgmt/Limit/Add"));

export const Rate = lazy(() => import("pages/marketingMgmt/Rate"));
export const RateAdd = lazy(() => import("pages/marketingMgmt/Rate/Add"));

export const Point = lazy(() => import("pages/marketingMgmt/Point"));
export const PointAdd = lazy(() => import("pages/marketingMgmt/Point/Add"));

export const CampaignTransactionHistory = lazy(() =>
  import("pages/reports/CampaignTransactionHistory"),
);

export const ReferralCodeDetailReport = lazy(() =>
  import("pages/reports/ReferralCodeDetailReport"),
);

export const ReferralTransactionHistory = lazy(() =>
  import("pages/reports/ReferralTransactionHistory"),
);

export const OutstandingPoint = lazy(() =>
  import("pages/reports/OutstandingPoint"),
);

export const LogStatus = lazy(() => import("pages/system/LogStatus"));

export const UserRegister = lazy(() => import("pages/system/user/Add"));
