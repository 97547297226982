import {
  REQUEST_POINT_CAMPAIGN,
  REQUEST_POINT_CAMPAIGN_DELETE,
  REQUEST_POINT_CAMPAIGN_DELETE_ERROR,
  REQUEST_POINT_CAMPAIGN_DELETE_SUCCESS,
  REQUEST_POINT_CAMPAIGN_DETAIL,
  REQUEST_POINT_CAMPAIGN_DETAIL_ERROR,
  REQUEST_POINT_CAMPAIGN_DETAIL_SUCCESS,
  REQUEST_POINT_CAMPAIGN_ERROR,
  REQUEST_POINT_CAMPAIGN_PAUSE,
  REQUEST_POINT_CAMPAIGN_PAUSE_ERROR,
  REQUEST_POINT_CAMPAIGN_PAUSE_SUCCESS,
  REQUEST_POINT_CAMPAIGN_PLAY,
  REQUEST_POINT_CAMPAIGN_PLAY_ERROR,
  REQUEST_POINT_CAMPAIGN_PLAY_SUCCESS,
  REQUEST_POINT_CAMPAIGN_STOP,
  REQUEST_POINT_CAMPAIGN_STOP_ERROR,
  REQUEST_POINT_CAMPAIGN_STOP_SUCCESS,
  REQUEST_POINT_CAMPAIGN_SUCCESS,
  REQUEST_POST_POINT_CAMPAIGN,
  REQUEST_POST_POINT_CAMPAIGN_ERROR,
  REQUEST_POST_POINT_CAMPAIGN_SUCCESS,
  REQUEST_POST_POINT_CAMPAIGN_UPDATE,
  REQUEST_POST_POINT_CAMPAIGN_UPDATE_ERROR,
  REQUEST_POST_POINT_CAMPAIGN_UPDATE_SUCCESS,
} from "actions/types";

const initialState = {
  loading: false,
  error: null,
  pointCampaigns: null,
  detailLoading: false,
  detailError: true,
  pointCampaign: null,
  postLoading: false,
  postError: null,
  postStatus: null,
  editLoading: false,
  editError: null,
  editStatus: null,
  deleteLoading: false,
  deleteError: null,
  deleteStatus: null,
  playLoading: false,
  playError: null,
  playStatus: null,
  pauseLoading: false,
  pauseError: null,
  pauseStatus: null,
  stopLoading: false,
  stopError: null,
  stopStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_POINT_CAMPAIGN:
      return {
        ...state,
        loading: true,
        error: null,
        postStatus: null,
        editStatus: null,
        deleteStatus: null,
      };
    case REQUEST_POINT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pointCampaigns: action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_POINT_CAMPAIGN_ERROR:
      return { ...state, loading: false, error: action.payload };

    case REQUEST_POINT_CAMPAIGN_DETAIL:
      return { ...state, detailLoading: true, detailError: null };
    case REQUEST_POINT_CAMPAIGN_DETAIL_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detailError: null,
        pointCampaign: action.payload.data,
      };
    case REQUEST_POINT_CAMPAIGN_DETAIL_ERROR:
      return { ...state, detailLoading: false, detailError: action.payload };

    case REQUEST_POST_POINT_CAMPAIGN:
      return { ...state, postLoading: true, postError: null };
    case REQUEST_POST_POINT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: null,
        postStatus: action.payload,
      };
    case REQUEST_POST_POINT_CAMPAIGN_ERROR:
      return { ...state, postLoading: false, postError: action.payload };

    case REQUEST_POST_POINT_CAMPAIGN_UPDATE:
      return { ...state, editLoading: true, editError: null };
    case REQUEST_POST_POINT_CAMPAIGN_UPDATE_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: null,
        editStatus: action.payload,
      };
    case REQUEST_POST_POINT_CAMPAIGN_UPDATE_ERROR:
      return { ...state, editLoading: false, editError: action.payload };

    case REQUEST_POINT_CAMPAIGN_DELETE:
      return { ...state, deleteLoading: true, deleteError: null };
    case REQUEST_POINT_CAMPAIGN_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: null,
        deleteStatus: action.payload,
      };
    case REQUEST_POINT_CAMPAIGN_DELETE_ERROR:
      return { ...state, deleteLoading: false, deleteError: action.payload };

    case REQUEST_POINT_CAMPAIGN_PLAY:
      return { ...state, playLoading: true, playError: null };
    case REQUEST_POINT_CAMPAIGN_PLAY_SUCCESS:
      return {
        ...state,
        playLoading: false,
        playError: null,
        playStatus: action.payload,
      };
    case REQUEST_POINT_CAMPAIGN_PLAY_ERROR:

    case REQUEST_POINT_CAMPAIGN_PAUSE:
      return { ...state, pauseLoading: true, pauseError: null };
    case REQUEST_POINT_CAMPAIGN_PAUSE_SUCCESS:
      return {
        ...state,
        pauseLoading: false,
        pauseError: null,
        pauseStatus: action.payload,
      };
    case REQUEST_POINT_CAMPAIGN_PAUSE_ERROR:

    case REQUEST_POINT_CAMPAIGN_STOP:
      return { ...state, stopLoading: true, stopError: null };
    case REQUEST_POINT_CAMPAIGN_STOP_SUCCESS:
      return {
        ...state,
        stopLoading: false,
        stopError: null,
        stopStatus: action.payload,
      };
    case REQUEST_POINT_CAMPAIGN_STOP_ERROR:

    default:
      return state;
  }
};
