import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { COLORS, pgSize } from "constants";

const CheckboxTable = ({
  columns,
  dataRows,
  targetValues,
  handleValueList,
  requestDataByPageNo,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(pgSize);

  useEffect(() => {
    dataRows?.pageSize && setRowsPerPage(dataRows?.pageSize);
  }, [dataRows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    requestDataByPageNo(rowsPerPage, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value;
    setRowsPerPage(perPage);
    setPage(0);

    requestDataByPageNo(perPage, 1);
  };

  const handleSelectedMerchants = (id) =>
    targetValues.find((value) => value === id);

  return (
    <Box sx={{ position: "relative" }}>
      <TableContainer sx={{ mt: 4, maxHeight: 640 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {dataRows?.count ? (
              dataRows?.data.map((row, index) => {
                return (
                  <TableRow hover key={index}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        value={row.id}
                        checked={
                          handleSelectedMerchants(row.id) === row.id
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          const currentStatus = e.target.checked;
                          handleValueList(currentStatus, row);
                        }}
                      />
                    </TableCell>

                    {columns.map((column, index) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={index} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={columns.length}
                  sx={{ color: COLORS.error }}
                >
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[pgSize, 25, 100]}
        component="div"
        count={dataRows?.count ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default CheckboxTable;
