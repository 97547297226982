import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getFunctionPermission } from "utils";

const RestrictedRoute = ({
  children,
  moduleName,
  menuName,
  operationButton,
}) => {
  const { permissions } = useSelector((state) => state.auth);
  const result = getFunctionPermission(
    permissions,
    moduleName,
    menuName,
    operationButton,
  );

  if (result && !result?.checkFlag) {
    return <Navigate to="/unAuthorize" replace />;
  }
  return children;
};

export default RestrictedRoute;
