import React from "react";
import {
  TextInput,
  EventTextInput,
  PasswordInput,
  NumberInput,
  SelectBox,
  MultiSelect,
  AutoComplete,
  RadioButton,
  CheckBox,
  SwitchInput,
  DatePickerInput,
  DateTimePickerInput,
  DateRangeInput,
  TimePickerInput,
  UploadFileInput,
  DropzoneFileUpload,
} from "components/common/FormHelper/Forms";

const FormControl = ({ type, ...rest }) => {
  switch (type) {
    case "text":
      return <TextInput {...rest} />;

    case "eventText":
      return <EventTextInput {...rest} />;

    case "password":
      return <PasswordInput {...rest} />;

    case "number":
      return <NumberInput {...rest} />;

    case "select":
      return <SelectBox {...rest} />;

    case "multiSelect":
      return <MultiSelect {...rest} />;

    case "autoComplete":
      return <AutoComplete {...rest} />;

    case "radio":
      return <RadioButton {...rest} />;

    case "checkBox":
      return <CheckBox {...rest} />;

    case "switch":
      return <SwitchInput {...rest} />;

    case "datePicker":
      return <DatePickerInput {...rest} />;

    case "dateTimePicker":
      return <DateTimePickerInput {...rest} />;

    case "dateRangePicker":
      return <DateRangeInput {...rest} />;

    case "timePicker":
      return <TimePickerInput {...rest} />;

    case "uploadFile":
      return <UploadFileInput {...rest} />;

    case "multiUploadFile":
      return <DropzoneFileUpload {...rest} />;

    default:
      return null;
  }
};

export default FormControl;
