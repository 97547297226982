import { useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { ItemTable } from "components/common";
import { requestUserGroupImportDetail } from "actions";
import { pgSize, pgNo } from "constants";

const MerchantSuccessList = ({ groupId }) => {
  const { importDetailLoading, importDetailStatus } = useSelector(
    (state) => state.userGroupData,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    requestDataByPageNo(pgSize, pgNo);
  }, [groupId]);

  const requestDataByPageNo = (pageSize, pageNo) => {
    dispatch(requestUserGroupImportDetail({ groupId }, { pageSize, pageNo }));
  };

  const columns = [
    { id: "merchantId", label: "Merchant ID", minWidth: 100 },
    {
      id: "merchantName",
      label: "Merchant Name",
      minWidth: 100,
      align: "right",
    },
  ];

  return (
    <Box>
      <ItemTable
        loading={importDetailLoading}
        columns={columns}
        dataRows={importDetailStatus}
        requestDataByPageNo={requestDataByPageNo}
      />
    </Box>
  );
};

export default MerchantSuccessList;
