import {
  REQUEST_USER_GROUP,
  REQUEST_USER_GROUP_SUCCESS,
  REQUEST_USER_GROUP_ERROR,
  REQUEST_USER_GROUP_DETAIL,
  REQUEST_USER_GROUP_DETAIL_SUCCESS,
  REQUEST_USER_GROUP_DETAIL_ERROR,
  REQUEST_POST_USER_GROUP,
  REQUEST_POST_USER_GROUP_SUCCESS,
  REQUEST_POST_USER_GROUP_ERROR,
  REQUEST_POST_USER_GROUP_UPDATE,
  REQUEST_POST_USER_GROUP_UPDATE_SUCCESS,
  REQUEST_POST_USER_GROUP_UPDATE_ERROR,
  REQUEST_POST_USER_GROUP_DELETE,
  REQUEST_POST_USER_GROUP_DELETE_SUCCESS,
  REQUEST_POST_USER_GROUP_DELETE_ERROR,
  REQUEST_FILE_UPLOAD_DATA,
  REQUEST_FILE_UPLOAD_DATA_SUCCESS,
  REQUEST_FILE_UPLOAD_DATA_ERROR,
  REQUEST_USER_GROUP_IMPORT,
  REQUEST_USER_GROUP_IMPORT_ERROR,
  REQUEST_USER_GROUP_IMPORT_SUCCESS,
  REQUEST_USER_GROUP_IMPORT_DETAIL,
  REQUEST_USER_GROUP_IMPORT_DETAIL_SUCCESS,
  REQUEST_USER_GROUP_IMPORT_DETAIL_ERROR,
  REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD,
  REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD_ERROR,
  REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD_SUCCESS,
  REQUEST_USER_GROUP_IMPORT_UPDATE,
  REQUEST_USER_GROUP_IMPORT_UPDATE_SUCCESS,
  REQUEST_USER_GROUP_IMPORT_UPDATE_ERROR,
  REQUEST_POST_CREATE_USER_GROUP,
  REQUEST_POST_CREATE_USER_GROUP_SUCCESS,
  REQUEST_POST_CREATE_USER_GROUP_ERROR,
  REQUEST_POST_USER_GROUP_ATTACHMENT,
  REQUEST_POST_USER_GROUP_ATTACHMENT_SUCCESS,
  REQUEST_POST_USER_GROUP_ATTACHMENT_ERROR,
} from "actions/types";

const initialState = {
  loading: false,
  error: null,
  userGroups: null,
  detailLoading: false,
  detailError: true,
  userGroup: null,
  postLoading: false,
  postError: null,
  postStatus: null,
  editLoading: false,
  editError: null,
  editStatus: null,
  deleteLoading: false,
  deleteError: null,
  deleteStatus: null,
  postUploadFileLoading: false,
  postUploadFile: null,
  postUploadFileError: null,
  importLoading: false,
  importError: null,
  importStatus: null,
  importDetailLoading: false,
  importDetailError: null,
  importDetailStatus: null,
  importUpdateLoading: false,
  importUpdateError: null,
  importUpdateData: null,
  importDetailDownloadLoading: false,
  importDetailDownloadError: null,
  importDetailDownloadData: null,
  createUserGroupLoading: false,
  createUserGroup: null,
  createUserGroupError: null,
  userGroupAttachmentLoading: false,
  userGroupAttachment: null,
  userGroupAttachmentError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_USER_GROUP:
      return {
        ...state,
        loading: true,
        error: null,
        postStatus: null,
        editStatus: null,
        deleteStatus: null,
        importDetailDownloadData: null,
        createUserGroup: null,
        importDetailStatus: null,
        userGroupAttachment: null,
      };
    case REQUEST_USER_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        importStatus: null,
        importUpdateData: null,
        userGroups: action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_USER_GROUP_ERROR:
      return { ...state, loading: false, error: action.payload };

    case REQUEST_USER_GROUP_DETAIL:
      return {
        ...state,
        detailLoading: true,
        detailError: null,
        userGroup: null,
        userGroupAttachment: null,
        importDetailDownloadData: null,
      };
    case REQUEST_USER_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detailError: null,
        userGroup: action.payload?.data,
      };
    case REQUEST_USER_GROUP_DETAIL_ERROR:
      return { ...state, detailLoading: false, detailError: action.payload };

    case REQUEST_POST_USER_GROUP:
      return {
        ...state,
        postLoading: true,
        postError: null,
      };
    case REQUEST_POST_USER_GROUP_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: null,
        postStatus: action.payload,
      };
    case REQUEST_POST_USER_GROUP_ERROR:
      return { ...state, postLoading: false, postError: action.payload };

    case REQUEST_POST_USER_GROUP_UPDATE:
      return { ...state, editLoading: true, editError: null };
    case REQUEST_POST_USER_GROUP_UPDATE_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: null,
        editStatus: action.payload,
      };
    case REQUEST_POST_USER_GROUP_UPDATE_ERROR:
      return { ...state, editLoading: false, editError: action.payload };

    case REQUEST_POST_USER_GROUP_DELETE:
      return { ...state, deleteLoading: true, deleteError: null };
    case REQUEST_POST_USER_GROUP_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: null,
        deleteStatus: action.payload,
      };
    case REQUEST_POST_USER_GROUP_DELETE_ERROR:
      return { ...state, deleteLoading: false, deleteError: action.payload };
    default:
      return state;

    case REQUEST_FILE_UPLOAD_DATA:
      return {
        ...state,
        postUploadFileLoading: true,
        postUploadFileError: null,
      };
    case REQUEST_FILE_UPLOAD_DATA_SUCCESS:
      return {
        ...state,
        postUploadFileLoading: false,
        postUploadFileError: null,
        postUploadFile: action.payload,
      };
    case REQUEST_FILE_UPLOAD_DATA_ERROR:
      return {
        ...state,
        postUploadFileLoading: false,
        postUploadFileError: action.payload,
      };

    case REQUEST_USER_GROUP_IMPORT:
      return {
        ...state,
        importLoading: true,
        importError: null,
        importStatus: null,
      };
    case REQUEST_USER_GROUP_IMPORT_SUCCESS:
      return {
        ...state,
        importLoading: false,
        importError: null,
        importStatus: action.payload,
      };
    case REQUEST_USER_GROUP_IMPORT_ERROR:
      return {
        ...state,
        importLoading: false,
        importError: action.payload,
      };

    case REQUEST_USER_GROUP_IMPORT_DETAIL:
      return {
        ...state,
        importDetailLoading: true,
        importDetailError: null,
        importDetailStatus: null,
        importDetailDownloadData: null,
      };
    case REQUEST_USER_GROUP_IMPORT_DETAIL_SUCCESS:
      return {
        ...state,
        importDetailLoading: false,
        importDetailError: null,
        importDetailStatus: action.payload,
      };
    case REQUEST_USER_GROUP_IMPORT_DETAIL_ERROR:
      return {
        ...state,
        importDetailLoading: false,
        importDetailError: action.payload,
      };

    case REQUEST_USER_GROUP_IMPORT_UPDATE:
      return {
        ...state,
        importUpdateLoading: true,
        importUpdateError: null,
      };
    case REQUEST_USER_GROUP_IMPORT_UPDATE_SUCCESS:
      return {
        ...state,
        importUpdateLoading: false,
        importUpdateError: null,
        importUpdateData: action.payload,
      };
    case REQUEST_USER_GROUP_IMPORT_UPDATE_ERROR:
      return {
        ...state,
        importUpdateLoading: false,
        importUpdateError: action.payload,
      };

    case REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD:
      return {
        ...state,
        importDetailDownloadLoading: true,
        importDetailDownloadError: null,
        importDetailDownloadData: null,
      };
    case REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD_SUCCESS:
      return {
        ...state,
        importDetailDownloadLoading: false,
        importDetailDownloadError: null,
        importDetailDownloadData: action.payload,
      };
    case REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD_ERROR:
      return {
        ...state,
        importDetailDownloadLoading: false,
        importDetailDownloadError: action.payload,
      };

    case REQUEST_POST_CREATE_USER_GROUP:
      return {
        ...state,
        createUserGroupLoading: true,
        createUserGroupError: null,
        createUserGroup: null,
        userGroupAttachment: null,
        importDetailStatus: null,
      };
    case REQUEST_POST_CREATE_USER_GROUP_SUCCESS:
      return {
        ...state,
        createUserGroupLoading: false,
        createUserGroupError: null,
        createUserGroup: action.payload,
      };
    case REQUEST_POST_CREATE_USER_GROUP_ERROR:
      return {
        ...state,
        createUserGroupLoading: false,
        createUserGroupError: action.payload,
      };

    case REQUEST_POST_USER_GROUP_ATTACHMENT:
      return {
        ...state,
        userGroupAttachmentLoading: true,
        userGroupAttachmentError: null,
        userGroupAttachment: null,
        //createUserGroup: null,
      };
    case REQUEST_POST_USER_GROUP_ATTACHMENT_SUCCESS:
      return {
        ...state,
        userGroupAttachmentLoading: false,
        userGroupAttachmentError: null,
        userGroupAttachment: action.payload,
      };
    case REQUEST_POST_USER_GROUP_ATTACHMENT_ERROR:
      return {
        ...state,
        userGroupAttachmentLoading: false,
        userGroupAttachmentError: action.payload,
      };
  }
};
