import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const ItemCard = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          Dashboard
        </Typography>

        <Typography variant="body2">will be coming soon.</Typography>
      </CardContent>
    </Card>
  );
};

export default ItemCard;
