import {
  REQUEST_ISSUER_MANAGEMENT,
  REQUEST_ISSUER_MANAGEMENT_SUCCESS,
  REQUEST_ISSUER_MANAGEMENT_ERROR,
  REQUEST_ISSUER_MANAGEMENT_DETAIL,
  REQUEST_ISSUER_MANAGEMENT_DETAIL_SUCCESS,
  REQUEST_ISSUER_MANAGEMENT_DETAIL_ERROR,
  REQUEST_POST_ISSUER_MANAGEMENT,
  REQUEST_POST_ISSUER_MANAGEMENT_SUCCESS,
  REQUEST_POST_ISSUER_MANAGEMENT_ERROR,
  REQUEST_POST_ISSUER_MANAGEMENT_UPDATE,
  REQUEST_POST_ISSUER_MANAGEMENT_UPDATE_SUCCESS,
  REQUEST_POST_ISSUER_MANAGEMENT_UPDATE_ERROR,
  REQUEST_POST_ISSUER_MANAGEMENT_DELETE,
  REQUEST_POST_ISSUER_MANAGEMENT_DELETE_SUCCESS,
  REQUEST_POST_ISSUER_MANAGEMENT_DELETE_ERROR,
  REQUEST_POST_SEND_MAIL,
  REQUEST_POST_SEND_MAIL_SUCCESS,
  REQUEST_POST_SEND_MAIL_ERROR,
  REQUEST_ACCOUNT_NUMBER,
  REQUEST_ACCOUNT_NUMBER_SUCCESS,
  REQUEST_ACCOUNT_NUMBER_ERROR,
} from "actions/types";

const initialState = {
  loading: false,
  error: null,
  issuers: null,
  detailLoading: false,
  detailError: true,
  issuer: null,
  postLoading: false,
  postError: null,
  postStatus: null,
  editLoading: false,
  editError: null,
  editStatus: null,
  deleteLoading: false,
  deleteError: null,
  deleteStatus: null,
  sendMailLoading: false,
  sendMailError: null,
  sendMailStatus: null,
  accNumLoading: false,
  accNumError: null,
  accNumber: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ISSUER_MANAGEMENT:
      return {
        ...state,
        loading: true,
        error: null,
        postStatus: null,
        editStatus: null,
        deleteStatus: null,
        sendMailStatus: null,
        accNumber: null,
        issuer: null,
      };
    case REQUEST_ISSUER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        issuers: action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_ISSUER_MANAGEMENT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case REQUEST_ISSUER_MANAGEMENT_DETAIL:
      return { ...state, detailLoading: true, detailError: null };
    case REQUEST_ISSUER_MANAGEMENT_DETAIL_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detailError: null,
        issuer: action.payload.data,
      };
    case REQUEST_ISSUER_MANAGEMENT_DETAIL_ERROR:
      return { ...state, detailLoading: false, detailError: action.payload };

    case REQUEST_POST_ISSUER_MANAGEMENT:
      return {
        ...state,
        postLoading: true,
        postError: null,
        accNumber: null,
      };
    case REQUEST_POST_ISSUER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: null,
        postStatus: action.payload,
      };
    case REQUEST_POST_ISSUER_MANAGEMENT_ERROR:
      return { ...state, postLoading: false, postError: action.payload };

    case REQUEST_POST_ISSUER_MANAGEMENT_UPDATE:
      return { ...state, editLoading: true, editError: null };
    case REQUEST_POST_ISSUER_MANAGEMENT_UPDATE_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: null,
        editStatus: action.payload,
      };
    case REQUEST_POST_ISSUER_MANAGEMENT_UPDATE_ERROR:
      return { ...state, editLoading: false, editError: action.payload };

    case REQUEST_POST_ISSUER_MANAGEMENT_DELETE:
      return { ...state, deleteLoading: true, deleteError: null };
    case REQUEST_POST_ISSUER_MANAGEMENT_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: null,
        deleteStatus: action.payload,
      };
    case REQUEST_POST_ISSUER_MANAGEMENT_DELETE_ERROR:
      return { ...state, deleteLoading: false, deleteError: action.payload };

    case REQUEST_POST_SEND_MAIL:
      return { ...state, sendMailLoading: true, sendMailError: null };
    case REQUEST_POST_SEND_MAIL_SUCCESS:
      return {
        ...state,
        sendMailLoading: false,
        sendMailError: null,
        sendMailStatus: action.payload,
      };
    case REQUEST_POST_SEND_MAIL_ERROR:
      return {
        ...state,
        sendMailLoading: false,
        sendMailError: action.payload,
      };

    case REQUEST_ACCOUNT_NUMBER:
      return {
        ...state,
        accNumLoading: true,
        accNumError: null,
        accNumber: null,
      };
    case REQUEST_ACCOUNT_NUMBER_SUCCESS:
      return {
        ...state,
        accNumLoading: false,
        accNumError: null,
        accNumber: action.payload,
      };
    case REQUEST_ACCOUNT_NUMBER_ERROR:
      return {
        ...state,
        accNumLoading: false,
        accNumError: action.payload,
      };

    default:
      return state;
  }
};
