import React from "react";
import { Controller } from "react-hook-form";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@mui/material";

const RadioButton = ({ label, name, control, options }) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth margin="normal" error={error ? true : false}>
          <FormLabel id={label}>{label}</FormLabel>

          <RadioGroup {...field} row aria-labelledby={label}>
            {options.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>

          <FormHelperText error={error ? true : false}>
            {error ? error.message : ""}
          </FormHelperText>
        </FormControl>
      )}
      name={name}
      control={control}
    />
  );
};

export default RadioButton;
