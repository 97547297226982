import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const DateTimePickerInput = ({
  label,
  name,
  control,
  disabledPast,
  disableFuture,
}) => (
  <Controller
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateTimePicker"]}>
          <DateTimePicker
            label={label}
            value={dayjs(value)}
            disablePast={disabledPast ? true : false}
            disableFuture={disableFuture ? true : false}
            format="DD-MM-YYYY h:mm:ss A"
            //views={["day", "month", "year", "hours", "minutes", "seconds"]}
            onChange={(newValue) =>
              newValue ? onChange(newValue.$d) : onChange("")
            }
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                fullWidth: true,
                margin: "dense",
                error: error ? true : false,
                helperText: error ? error.message : "",
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    )}
    name={name}
    control={control}
  />
);

export default DateTimePickerInput;
