import { Player } from "@lottiefiles/react-lottie-player";
import loginLoading from "components/common/Spinner/LottieAnimation/login-animation.json";

const LoginAnimation = () => {
  return (
    <Player
      autoplay
      loop
      src={loginLoading}
      style={{
        height: "50%",
        width: "50%",
        marginLeft: 20,
      }}
    />
  );
};

export default LoginAnimation;
