import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import { HandleRequestMiddleware, AuthMiddleware } from "middlewares";
import reducers from "reducers";

let middlewares = [thunk, HandleRequestMiddleware, AuthMiddleware];

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middlewares)),
);
