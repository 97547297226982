import BookmarksIcon from "@mui/icons-material/Bookmarks";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SettingsIcon from "@mui/icons-material/Settings";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import DvrIcon from "@mui/icons-material/Dvr";
import { logoutHandler } from "utils";
import {
  ISSUER_ISSUERMANAGEMENT,
  MARKETINGMGMT_USERGROUP,
  MARKETINGMGMT_LIMIT,
  MARKETINGMGMT_RATE,
  CAMPAIGNMGMT_PAYMENT,
  CAMPAIGNMGMT_REFERRAL,
  REPORTS_REFERRALCODEDETAILREPORT,
  REPORTS_CAMPAIGNTRXREPORT,
  REPORTS_REFERRALTRXREPORT,
  SYSTEM_AUDITLOGS,
  SYSTEM_REGISTER,
  MARKETINGMGMT_POINT,
  CAMPAIGNMGMT_POINT,
  OUTSTANDING_POINT_REPORT,
} from "constants/menuCodes";

export const navBarItems = [
  {
    id: 0,
    label: "Dashboard",
    tooltip: "Dashboard",
    route: "/",
    icon: <BookmarksIcon fontSize="small" />,
  },
  {
    id: 1,
    label: "Issuers",
    tooltip: "Issuers",
    route: "",
    icon: <AssignmentIndIcon fontSize="small" />,
    subMenu: [
      {
        id: 0,
        code: ISSUER_ISSUERMANAGEMENT,
        label: "Issuer Management",
        route: "/issuers/issuer-management",
        icon: <ManageAccountsIcon fontSize="small" />,
      },
    ],
  },
  {
    id: 2,
    label: "Marketing Mgmt",
    tooltip: "Marketing Mgmt",
    route: "",
    icon: <DonutSmallIcon fontSize="small" />,
    subMenu: [
      {
        id: 0,
        code: MARKETINGMGMT_USERGROUP,
        label: "User Group",
        route: "marketing/user-group",
        icon: <GroupAddIcon fontSize="small" />,
      },
      {
        id: 1,
        code: MARKETINGMGMT_LIMIT,
        label: "Limit",
        route: "marketing/limit",
        icon: <MoneyOffIcon fontSize="small" />,
      },
      {
        id: 2,
        code: MARKETINGMGMT_RATE,
        label: "Rate",
        route: "marketing/rate",
        icon: <TrendingUpIcon fontSize="small" />,
      },
      {
        id: 3,
        code: MARKETINGMGMT_POINT,
        label: "Point",
        route: "marketing/point",
        icon: <AppShortcutIcon fontSize="small" />,
      },
    ],
  },
  {
    id: 2,
    label: "Campaign Mgmt",
    tooltip: "Campaign Mgmt",
    route: "",
    icon: <AssignmentIcon fontSize="small" />,
    subMenu: [
      {
        id: 0,
        code: CAMPAIGNMGMT_PAYMENT,
        label: "Payment Campaign Mgmt",
        route: "campaign/payment-campaign-management",
        icon: <MonetizationOnIcon fontSize="small" />,
      },
      {
        id: 1,
        code: CAMPAIGNMGMT_REFERRAL,
        label: "Referral Campaign Mgmt",
        route: "campaign/referral-campaign-management",
        icon: <SupervisedUserCircleIcon fontSize="small" />,
      },
      {
        id: 2,
        code: CAMPAIGNMGMT_POINT,
        label: "Point Campaign Mgmt",
        route: "campaign/point-campaign-management",
        icon: <AppShortcutIcon fontSize="small" />,
      },
    ],
  },
  {
    id: 2,
    label: "Reports",
    tooltip: "Reports",
    route: "",
    icon: <DvrIcon fontSize="small" />,
    subMenu: [
      {
        id: 1,
        code: REPORTS_REFERRALCODEDETAILREPORT,
        label: "Referral Trx History",
        route: "reports/referral-transaction-history",
        icon: <AssessmentIcon fontSize="small" />,
      },
      {
        id: 2,
        code: REPORTS_CAMPAIGNTRXREPORT,
        label: "Campaign Trx History",
        route: "reports/campaign-transaction-history",
        icon: <ReceiptLongIcon fontSize="small" />,
      },
      {
        id: 3,
        code: REPORTS_REFERRALTRXREPORT,
        label: "Referral Code Detail Report",
        route: "reports/referral-code-detail-report",
        icon: <ReceiptLongIcon fontSize="small" />,
      },
      {
        id: 4,
        code: OUTSTANDING_POINT_REPORT,
        label: "Outstanding Points",
        route: "reports/outstanding-points",
        icon: <ReceiptLongIcon fontSize="small" />,
      },
    ],
  },
  {
    id: 3,
    label: "System",
    tooltip: "System",
    route: "",
    icon: <SettingsIcon fontSize="small" />,
    subMenu: [
      {
        id: 0,
        code: SYSTEM_AUDITLOGS,
        label: "Audit Logs",
        route: "/system/log-status",
        icon: <ReceiptLongIcon fontSize="small" />,
      },
      {
        id: 1,
        code: SYSTEM_REGISTER,
        label: "Register",
        route: "/system/register",
        icon: <GroupAddIcon fontSize="small" />,
      },
    ],
  },
];

export const profileItems = [
  {
    id: 1,
    label: "Profile",
    route: "/",
  },
  {
    id: 2,
    label: "My Account",
    route: "/",
  },
  {
    id: 1,
    label: "Logout",
    route: null,
    cb: (dispatch, navigate, location) =>
      logoutHandler(dispatch, navigate, location),
  },
];
