import { styled } from "@mui/material/styles";
import { IconButton as MuiIconButton, Button } from "@mui/material";
import { COLORS, SIZES } from "constants";

export const IconButton = styled(MuiIconButton)({
  ".MuiSvgIcon-root": {
    border: `2px solid ${COLORS.secondary}`,
    color: `${COLORS.secondary} !important`,
    padding: 5,
    marginRight: 15,
    borderRadius: SIZES.base,
    "&:hover": {
      backgroundColor: COLORS.bgColor,
    },
  },
});

export const ContainedButton = styled(Button)({
  boxShadow: "0 0 10px rgba(0, 0, 0, 0)",
  "&:hover": {
    backgroundColor: COLORS.primary,
    color: COLORS.secondary,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0)",
  },
});
