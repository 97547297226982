import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const CommonDialog = ({
  maxWidth,
  open,
  handleDialogBox,
  dialogTitle,
  children,
}) => {
  return (
    <div>
      <Dialog
        maxWidth={maxWidth ?? "sm"}
        open={open}
        onClose={handleDialogBox}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogBox} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CommonDialog;
