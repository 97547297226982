import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { ItemTable, TooltipIconButton } from "components/common";

const ConsumerList = ({ dataRows, handleDeleteComsumerGroup }) => {
  const columns = [
    { id: "consumerName", label: "Consumer Name", minWidth: 120 },
    { id: "phone", label: "Mobile Number", minWidth: 100 },
    {
      id: "id",
      label: "Operation",
      minWidth: 100,
      align: "right",
      format: (value) => (
        <TooltipIconButton
          title="Delete"
          color="error"
          cb={() => handleDeleteComsumerGroup(value)}
        >
          <DeleteIcon fontSize="inherit" />
        </TooltipIconButton>
      ),
    },
  ];

  return (
    <Box>
      <ItemTable columns={columns} dataRows={dataRows} noPagination={true} />
    </Box>
  );
};

export default ConsumerList;
