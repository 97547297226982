import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { COLORS, pgSize } from "constants";

const ClickableTable = ({
  columns,
  dataRows,
  cb,
  handleDialogBox,
  noPagination,
  requestDataByPageNo,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(pgSize);

  useEffect(() => {
    dataRows?.pageSize && setRowsPerPage(dataRows?.pageSize);
  }, [dataRows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    requestDataByPageNo(rowsPerPage, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value;
    setRowsPerPage(perPage);
    setPage(0);

    requestDataByPageNo(perPage, 1);
  };

  return (
    <Box>
      <TableContainer sx={{ mt: 4, maxHeight: 640 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {noPagination ? (
              dataRows?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    onClick={() => {
                      cb(row);
                      handleDialogBox();
                    }}
                  >
                    {columns.map((column, index) => {
                      const value = row[column.id];

                      return (
                        <TableCell key={index} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : dataRows?.count ? (
              dataRows?.data?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    onClick={() => {
                      cb(row);
                      handleDialogBox();
                    }}
                  >
                    {columns.map((column, index) => {
                      const value = row[column.id];

                      return (
                        <TableCell key={index} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={columns.length}
                  sx={{ color: COLORS.error }}
                >
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!noPagination && (
        <TablePagination
          rowsPerPageOptions={[pgSize, 25, 100]}
          component="div"
          count={dataRows?.count ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

export default ClickableTable;
