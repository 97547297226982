import { Box, Typography } from "@mui/material";
import TextSnippetTwoToneIcon from "@mui/icons-material/TextSnippetTwoTone";

const SampleFileUploadImage = ({ fileObj, fileName }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <TextSnippetTwoToneIcon fontSize="large" />{" "}
      <Typography variant="subtitle2">
        {fileObj ? fileObj.name : fileName}
      </Typography>
    </Box>
  );
};

export default SampleFileUploadImage;
