import {
  REQUEST_AUTH,
  REQUEST_AUTH_SUCCESS,
  REQUEST_AUTH_ERROR,
  REQUEST_PERMISSIONS_BUTTON,
  REQUEST_PERMISSIONS_BUTTON_SUCCESS,
  REQUEST_PERMISSIONS_BUTTON_ERROR,
  REQUEST_LOGOUT,
  REQUEST_LOGOUT_SUCCESS,
  REQUEST_LOGOUT_ERROR,
  REQUEST_CLEAR_STATE,
  REQUEST_CLEAR_STATE_SUCCESS,
  REQUEST_CLEAR_STATE_ERROR,
} from "actions/types";

export const requestAuthrize = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_AUTH,
    success: REQUEST_AUTH_SUCCESS,
    error: REQUEST_AUTH_ERROR,
  },
  requestLink: "/authLogin/authenticate",
  payload,
});

export const requestRefreshToken = () => ({
  method: "POST",
  type: {
    pending: REQUEST_AUTH,
    success: REQUEST_AUTH_SUCCESS,
    error: REQUEST_AUTH_ERROR,
  },
  requestLink: "/authLogin/renewToken",
});

export const requestPermissionButton = () => ({
  method: "GET",
  type: {
    pending: REQUEST_PERMISSIONS_BUTTON,
    success: REQUEST_PERMISSIONS_BUTTON_SUCCESS,
    error: REQUEST_PERMISSIONS_BUTTON_ERROR,
  },
  requestLink: "/authLogin/permissionButton",
});

export const requestLogout = () => ({
  method: "GET",
  type: {
    pending: REQUEST_LOGOUT,
    success: REQUEST_LOGOUT_SUCCESS,
    error: REQUEST_LOGOUT_ERROR,
  },
  requestLink: "/login/logOut",
});

export const requestClearAllState = () => ({
  method: null,
  type: {
    pending: REQUEST_CLEAR_STATE,
    success: REQUEST_CLEAR_STATE_SUCCESS,
    error: REQUEST_CLEAR_STATE_ERROR,
  },
  requestLink: null,
});
