import React from "react";
import { IconButton } from "components/common/Button/style";

const CommonIconButton = ({ cb, size, label, edge, children }) => {
  return (
    <IconButton
      size={size}
      aria-label={label}
      edge={edge ? edge : false}
      onClick={cb}
      sx={{
        "&:hover": { backgroundColor: "transparent" },
      }}
    >
      {children}
    </IconButton>
  );
};

export default CommonIconButton;
