import {
  REQUEST_USER_GROUP,
  REQUEST_USER_GROUP_SUCCESS,
  REQUEST_USER_GROUP_ERROR,
  REQUEST_USER_GROUP_DETAIL,
  REQUEST_USER_GROUP_DETAIL_SUCCESS,
  REQUEST_USER_GROUP_DETAIL_ERROR,
  REQUEST_POST_USER_GROUP,
  REQUEST_POST_USER_GROUP_SUCCESS,
  REQUEST_POST_USER_GROUP_ERROR,
  REQUEST_POST_USER_GROUP_UPDATE,
  REQUEST_POST_USER_GROUP_UPDATE_SUCCESS,
  REQUEST_POST_USER_GROUP_UPDATE_ERROR,
  REQUEST_POST_USER_GROUP_DELETE,
  REQUEST_POST_USER_GROUP_DELETE_SUCCESS,
  REQUEST_POST_USER_GROUP_DELETE_ERROR,
  REQUEST_FILE_UPLOAD_DATA,
  REQUEST_FILE_UPLOAD_DATA_SUCCESS,
  REQUEST_FILE_UPLOAD_DATA_ERROR,
  REQUEST_USER_GROUP_IMPORT,
  REQUEST_USER_GROUP_IMPORT_SUCCESS,
  REQUEST_USER_GROUP_IMPORT_ERROR,
  REQUEST_USER_GROUP_IMPORT_DETAIL,
  REQUEST_USER_GROUP_IMPORT_DETAIL_SUCCESS,
  REQUEST_USER_GROUP_IMPORT_DETAIL_ERROR,
  REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD,
  REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD_SUCCESS,
  REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD_ERROR,
  REQUEST_USER_GROUP_IMPORT_UPDATE,
  REQUEST_USER_GROUP_IMPORT_UPDATE_SUCCESS,
  REQUEST_USER_GROUP_IMPORT_UPDATE_ERROR,
  REQUEST_POST_CREATE_USER_GROUP,
  REQUEST_POST_CREATE_USER_GROUP_SUCCESS,
  REQUEST_POST_CREATE_USER_GROUP_ERROR,
  REQUEST_POST_USER_GROUP_ATTACHMENT,
  REQUEST_POST_USER_GROUP_ATTACHMENT_SUCCESS,
  REQUEST_POST_USER_GROUP_ATTACHMENT_ERROR,
} from "actions/types";

export const requestUserGroups = (payload, params) => ({
  method: "POST",
  type: {
    pending: REQUEST_USER_GROUP,
    success: REQUEST_USER_GROUP_SUCCESS,
    error: REQUEST_USER_GROUP_ERROR,
  },
  requestLink: `/userGroup/userGrpList`,
  payload,
  params,
});

export const requestUserGroupDetail = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_USER_GROUP_DETAIL,
    success: REQUEST_USER_GROUP_DETAIL_SUCCESS,
    error: REQUEST_USER_GROUP_DETAIL_ERROR,
  },
  requestLink: `/userGroup/userGroupDetail`,
  payload,
});

export const requestPostUserGroup = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_POST_USER_GROUP,
    success: REQUEST_POST_USER_GROUP_SUCCESS,
    error: REQUEST_POST_USER_GROUP_ERROR,
  },
  requestLink: `/userGroup/insertUserGroup`,
  payload,
});

export const requestPostUserGroupUpdate = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_POST_USER_GROUP_UPDATE,
    success: REQUEST_POST_USER_GROUP_UPDATE_SUCCESS,
    error: REQUEST_POST_USER_GROUP_UPDATE_ERROR,
  },
  requestLink: `/userGroup/editUserGroup`,
  payload,
});

export const requestPostUserGroupDelete = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_POST_USER_GROUP_DELETE,
    success: REQUEST_POST_USER_GROUP_DELETE_SUCCESS,
    error: REQUEST_POST_USER_GROUP_DELETE_ERROR,
  },
  requestLink: `/userGroup/deleteUserGroup`,
  payload,
});

export const requestPostUploadFile = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_FILE_UPLOAD_DATA,
    success: REQUEST_FILE_UPLOAD_DATA_SUCCESS,
    error: REQUEST_FILE_UPLOAD_DATA_ERROR,
  },
  requestLink: ``,
  payload,
  postDataType: "image",
});

export const requestUserGroupImport = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_USER_GROUP_IMPORT,
    success: REQUEST_USER_GROUP_IMPORT_SUCCESS,
    error: REQUEST_USER_GROUP_IMPORT_ERROR,
  },
  requestLink: `/userGroup/insertUserGroupImport`,
  payload,
  postDataType: "image",
});

export const requestUserGroupImportUpdate = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_POST_USER_GROUP_ATTACHMENT,
    success: REQUEST_POST_USER_GROUP_ATTACHMENT_SUCCESS,
    error: REQUEST_POST_USER_GROUP_ATTACHMENT_ERROR,
  },
  requestLink: `/userGroup/editUserGroupImport`,
  payload,
  postDataType: "image",
});

export const requestUserGroupImportDetail = (payload, params) => ({
  method: "POST",
  type: {
    pending: REQUEST_USER_GROUP_IMPORT_DETAIL,
    success: REQUEST_USER_GROUP_IMPORT_DETAIL_SUCCESS,
    error: REQUEST_USER_GROUP_IMPORT_DETAIL_ERROR,
  },
  requestLink: `/userGroup/userGroupDetailList`,
  payload,
  params,
});

export const requestUserGroupImportDetailDownload = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD,
    success: REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD_SUCCESS,
    error: REQUEST_USER_GROUP_IMPORT_DETAIL_DOWNLOAD_ERROR,
  },
  requestLink: `/userGroup/userGroupDetailList`,
  payload,
  params: { pageSize: 10000, pageNo: 1 },
});

export const requestPostCreateUserGroup = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_POST_CREATE_USER_GROUP,
    success: REQUEST_POST_CREATE_USER_GROUP_SUCCESS,
    error: REQUEST_POST_CREATE_USER_GROUP_ERROR,
  },
  requestLink: `/userGroup/insertUserGroup`,
  payload,
});

export const requestPostUserGroupAttachment = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_POST_USER_GROUP_ATTACHMENT,
    success: REQUEST_POST_USER_GROUP_ATTACHMENT_SUCCESS,
    error: REQUEST_POST_USER_GROUP_ATTACHMENT_ERROR,
  },
  requestLink: `/userGroup/insertUserGroupImport`,
  payload,
  postDataType: "image",
});
