import {
  REQUEST_ISSUERS,
  REQUEST_ISSUERS_SUCCESS,
  REQUEST_ISSUERS_ERROR,
  REQUEST_USER_GROUP,
  REQUEST_USER_GROUP_SUCCESS,
  REQUEST_USER_GROUP_ERROR,
  REQUEST_MERCHANT_USER_GROUP,
  REQUEST_MERCHANT_USER_GROUP_SUCCESS,
  REQUEST_MERCHANT_USER_GROUP_ERROR,
  REQUEST_CONSUMER_USER_GROUP,
  REQUEST_CONSUMER_USER_GROUP_SUCCESS,
  REQUEST_CONSUMER_USER_GROUP_ERROR,
  REQUEST_LIMIT,
  REQUEST_LIMIT_SUCCESS,
  REQUEST_LIMIT_ERROR,
  REQUEST_RATE,
  REQUEST_RATE_SUCCESS,
  REQUEST_RATE_ERROR,
  REQUEST_MERCHANTS,
  REQUEST_MERCHANTS_SUCCESS,
  REQUEST_MERCHANTS_ERROR,
  REQUEST_ALL_MERCHANTS,
  REQUEST_ALL_MERCHANTS_SUCCESS,
  REQUEST_ALL_MERCHANTS_ERROR,
  REQUEST_CONSUMERS,
  REQUEST_CONSUMERS_SUCCESS,
  REQUEST_CONSUMERS_ERROR,
  REQUEST_ALL_CONSUMERS,
  REQUEST_ALL_CONSUMERS_SUCCESS,
  REQUEST_ALL_CONSUMERS_ERROR,
  REQUEST_APP_NAME,
  REQUEST_APP_NAME_SUCCESS,
  REQUEST_APP_NAME_ERROR,
  REQUEST_ROLE_NAME,
  REQUEST_ROLE_NAME_SUCCESS,
  REQUEST_ROLE_NAME_ERROR,
  REQUEST_POINT_CONFIG,
  REQUEST_POINT_CONFIG_SUCCESS,
  REQUEST_POINT_CONFIG_ERROR,
} from "actions/types";

export const requestIssuers = (payload, params) => ({
  method: "POST",
  type: {
    pending: REQUEST_ISSUERS,
    success: REQUEST_ISSUERS_SUCCESS,
    error: REQUEST_ISSUERS_ERROR,
  },
  requestLink: `/issuer/searchIssuerList`,
  payload,
  params,
});

export const requestUserGroup = (payload, params) => ({
  method: "POST",
  type: {
    pending: REQUEST_USER_GROUP,
    success: REQUEST_USER_GROUP_SUCCESS,
    error: REQUEST_USER_GROUP_ERROR,
  },
  requestLink: `/userGroup/userGrpList`,
  payload,
  params,
});

export const requestMerchantUserGroup = (payload, params) => ({
  method: "POST",
  type: {
    pending: REQUEST_MERCHANT_USER_GROUP,
    success: REQUEST_MERCHANT_USER_GROUP_SUCCESS,
    error: REQUEST_MERCHANT_USER_GROUP_ERROR,
  },
  requestLink: `/userGroup/userGrpList`,
  payload,
  params,
});

export const requestConsumerUserGroup = (payload, params) => ({
  method: "POST",
  type: {
    pending: REQUEST_CONSUMER_USER_GROUP,
    success: REQUEST_CONSUMER_USER_GROUP_SUCCESS,
    error: REQUEST_CONSUMER_USER_GROUP_ERROR,
  },
  requestLink: `/userGroup/userGrpList`,
  payload,
  params,
});

export const requestLimits = (payload, params) => ({
  method: "POST",
  type: {
    pending: REQUEST_LIMIT,
    success: REQUEST_LIMIT_SUCCESS,
    error: REQUEST_LIMIT_ERROR,
  },
  requestLink: `/limit/limitList`,
  payload,
  params,
});

export const requestRateList = (payload, params) => ({
  method: "POST",
  type: {
    pending: REQUEST_RATE,
    success: REQUEST_RATE_SUCCESS,
    error: REQUEST_RATE_ERROR,
  },
  requestLink: `/rate/searchRateList`,
  payload,
  params,
});

export const requestMerchants = (payload, params) => ({
  method: "POST",
  type: {
    pending: REQUEST_MERCHANTS,
    success: REQUEST_MERCHANTS_SUCCESS,
    error: REQUEST_MERCHANTS_ERROR,
  },
  requestLink: `/merchant/searchMerchantList`,
  payload,
  params,
});

export const requestAllMerchants = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_ALL_MERCHANTS,
    success: REQUEST_ALL_MERCHANTS_SUCCESS,
    error: REQUEST_ALL_MERCHANTS_ERROR,
  },
  requestLink: `/merchant/searchMerchantList`,
  payload,
  params: { pageSize: 10000, pageNo: 1 },
});

export const requestConsumers = (payload, params) => ({
  method: "POST",
  type: {
    pending: REQUEST_CONSUMERS,
    success: REQUEST_CONSUMERS_SUCCESS,
    error: REQUEST_CONSUMERS_ERROR,
  },
  requestLink: `/consumer/searchConsumerList`,
  payload,
  params,
});

export const requestAllConsumers = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_ALL_CONSUMERS,
    success: REQUEST_ALL_CONSUMERS_SUCCESS,
    error: REQUEST_ALL_CONSUMERS_ERROR,
  },
  requestLink: `/consumer/searchConsumerList`,
  payload,
  params: { pageSize: 100, pageNo: 1 },
});

export const requestAppName = () => ({
  method: "GET",
  type: {
    pending: REQUEST_APP_NAME,
    success: REQUEST_APP_NAME_SUCCESS,
    error: REQUEST_APP_NAME_ERROR,
  },
  requestLink: `/authLogin/appEnquiry`,
});

export const requestRoleName = (payload) => ({
  method: "POST",
  type: {
    pending: REQUEST_ROLE_NAME,
    success: REQUEST_ROLE_NAME_SUCCESS,
    error: REQUEST_ROLE_NAME_ERROR,
  },
  requestLink: `/authLogin/rolesEnquiry`,
  payload,
});

export const requestPointConfig = (payload, params) => ({
  method: "POST",
  type: {
    pending: REQUEST_POINT_CONFIG,
    success: REQUEST_POINT_CONFIG_SUCCESS,
    error: REQUEST_POINT_CONFIG_ERROR,
  },
  requestLink: `/pointConfig/searchPointConfigList`,
  payload,
  params,
});
