import React from "react";
import { FormControl, FormLabel, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

const UploadFileInput = ({
  label,
  name,
  control,
  acceptType,
  fileInputRef,
}) => {
  return (
    <Controller
      render={({ field: { onChange }, fieldState: { error } }) => (
        <FormControl fullWidth margin="normal" error={error ? true : false}>
          <FormLabel component="legend" id={label}>
            {label}
          </FormLabel>

          <input
            accept={acceptType ?? "image/jpeg, image/png"}
            type="file"
            ref={fileInputRef}
            onChange={(e) => onChange(e.currentTarget.files[0])}
            style={{
              border: "1px solid #CCC",
              padding: 8,
              borderRadius: 3,
              width: "100%",
            }}
          />

          <FormHelperText error={true}>
            {error ? error.message : ""}
          </FormHelperText>
        </FormControl>
      )}
      name={name}
      control={control}
    />
  );
};

export default UploadFileInput;
