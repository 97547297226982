import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const TimePickerInput = ({ label, name, control }) => {
  return (
    <Controller
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label={label}
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                error={error ? true : false}
                helperText={error ? error.message : ""}
              />
            )}
          />
        </LocalizationProvider>
      )}
      name={name}
      control={control}
    />
  );
};

export default TimePickerInput;
