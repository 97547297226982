import {
  REQUEST_RATE,
  REQUEST_RATE_SUCCESS,
  REQUEST_RATE_ERROR,
  REQUEST_RATE_DETAIL,
  REQUEST_RATE_DETAIL_SUCCESS,
  REQUEST_RATE_DETAIL_ERROR,
  REQUEST_RATE_TYPE,
  REQUEST_RATE_TYPE_SUCCESS,
  REQUEST_RATE_TYPE_ERROR,
  REQUEST_POST_RATE,
  REQUEST_POST_RATE_SUCCESS,
  REQUEST_POST_RATE_ERROR,
  REQUEST_POST_RATE_UPDATE,
  REQUEST_POST_RATE_UPDATE_SUCCESS,
  REQUEST_POST_RATE_UPDATE_ERROR,
  REQUEST_RATE_DELETE,
  REQUEST_RATE_DELETE_SUCCESS,
  REQUEST_RATE_DELETE_ERROR,
} from "actions/types";

const initialState = {
  loading: false,
  error: null,
  rates: null,
  detailLoading: false,
  detailError: true,
  rate: null,
  typeLoading: false,
  typeError: null,
  types: [],
  postLoading: false,
  postError: null,
  postStatus: null,
  editLoading: false,
  editError: null,
  editStatus: null,
  deleteLoading: false,
  deleteError: null,
  deleteStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_RATE:
      return {
        ...state,
        loading: true,
        error: null,
        postStatus: null,
        editStatus: null,
        deleteStatus: null,
      };
    case REQUEST_RATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        rates: action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_RATE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case REQUEST_RATE_DETAIL:
      return { ...state, detailLoading: true, detailError: null };
    case REQUEST_RATE_DETAIL_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detailError: null,
        rate: action.payload.data,
      };
    case REQUEST_RATE_DETAIL_ERROR:
      return { ...state, detailLoading: false, detailError: action.payload };

    case REQUEST_RATE_TYPE:
      return { ...state, typeLoading: true, typeError: null };
    case REQUEST_RATE_TYPE_SUCCESS:
      return {
        ...state,
        typeLoading: false,
        typeError: null,
        types: [...action.payload.data],
      };
    case REQUEST_RATE_TYPE_ERROR:
      return { ...state, typeLoading: false, typeError: action.payload };

    case REQUEST_POST_RATE:
      return { ...state, postLoading: true, postError: null };
    case REQUEST_POST_RATE_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: null,
        postStatus: action.payload,
      };
    case REQUEST_POST_RATE_ERROR:
      return { ...state, postLoading: false, postError: action.payload };

    case REQUEST_POST_RATE_UPDATE:
      return { ...state, editLoading: true, editError: null };
    case REQUEST_POST_RATE_UPDATE_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: null,
        editStatus: action.payload,
      };
    case REQUEST_POST_RATE_UPDATE_ERROR:
      return { ...state, editLoading: false, editError: action.payload };

    case REQUEST_RATE_DELETE:
      return { ...state, deleteLoading: true, deleteError: null };
    case REQUEST_RATE_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: null,
        deleteStatus: action.payload,
      };
    case REQUEST_RATE_DELETE_ERROR:
      return { ...state, deleteLoading: false, deleteError: action.payload };

    default:
      return state;
  }
};
