import { combineReducers } from "redux";
import { REQUEST_CLEAR_STATE } from "actions/types";
import auth from "./AuthReducer";
import issuerMgmt from "./IssuerMgmtReducer";
import PopupData from "./PopupReducer";
import LimitReducer from "./LimitReducer";
import rateData from "./RateReducer";
import transactionTypeData from "./TransactionTypeReducer";
import pointData from "./PointReducer";
import pointCampaignData from "./PointCampaignReducer";
import paymentCampaignData from "./PaymentCampaignReducer";
import referralCampaignData from "./ReferralCampaignReducer";
import userGroupData from "./UserGroupReducer";
import Reports from "./ReportReducer";
import Users from "./UserRegisterReducer";

const appReducer = combineReducers({
  auth,
  issuerMgmt,
  PopupData,
  LimitReducer,
  rateData,
  transactionTypeData,
  pointData,
  pointCampaignData,
  paymentCampaignData,
  referralCampaignData,
  userGroupData,
  Reports,
  Users,
});

const rootReducer = (state, action) => {
  if (action.type === REQUEST_CLEAR_STATE) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
