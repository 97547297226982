import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PropTypes from "prop-types";

const DatePickerInput = ({ label, name, control, disabledPast }) => (
  <Controller
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label={label}
            value={dayjs(value)}
            format="DD-MM-YYYY"
            disablePast={disabledPast ? true : false}
            onChange={(newValue) =>
              newValue ? onChange(newValue.$d) : onChange("")
            }
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                fullWidth: true,
                margin: "dense",
                error: error ? true : false,
                helperText: error ? error.message : "",
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    )}
    name={name}
    control={control}
  />
);

DatePickerInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};

export default DatePickerInput;
