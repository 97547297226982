import React, { Suspense, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { LeftSideBarDrawer, LoadingSpinner } from "components/common";
import { COLORS, SIZES } from "constants";
import { requestPermissionButton } from "actions";

const PageLayout = () => {
  const { permissionsLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestPermissionButton());
  }, [dispatch]);

  if (permissionsLoading) return <LoadingSpinner />;

  return (
    <Box sx={{ display: "flex" }}>
      <LeftSideBarDrawer />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          mx: 2,
          mt: 8,
          minHeight: "88vh",
          backgroundColor: COLORS.bgColor,
          borderTopLeftRadius: SIZES.radius,
          borderTopRightRadius: SIZES.radius,
          position: "relative",
        }}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  );
};

export default PageLayout;
