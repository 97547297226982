import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";

const NumberInput = ({ label, name, control, required, disabled }) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <TextField
          type="number"
          size="small"
          margin="normal"
          label={label}
          id={name}
          fullWidth={true}
          helperText={error ? error.message : ""}
          required={required ? true : false}
          disabled={disabled ?? false}
          error={error ? true : false}
          InputProps={{ required: false }}
          {...field}
        />
      )}
      name={name}
      control={control}
    />
  );
};

NumberInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default NumberInput;
