import axios from "axios";
import { APP_API_URL, APLUS_API_URL } from "config/settings";
import { toast } from "react-toastify";
import { requestClearAllState, requestRefreshToken } from "actions";
import { getAuthToken, getTokenExpire } from "utils";

let isRenewTokenPending = false;

export default (store) => (next) => async (action) => {
  const { dispatch } = store;
  const { method, type, requestLink, hostURL } = action;
  const { pending, success, error } = type;
  const token = getAuthToken();

  // CREATE REQUEST OBJECT
  const requestObj = {
    method,
    url: hostURL
      ? `${APLUS_API_URL}${requestLink}`
      : `${APP_API_URL}${requestLink}`,
    headers: {
      appName: "Marketing",
    },
    //timeout: 5000,
  };

  // ADD token
  if (token) {
    requestObj.headers = {
      ...requestObj.headers,
      Authorization: `Bearer ${token}`,
      renewToken: token,
    };
  }

  // ADD Content-Type for image upload
  if (action.postDataType) {
    requestObj.headers = {
      ...requestObj.headers,
      "Content-Type": "multipart/form-data",
    };
  }

  // ADD ROUTE PARAM TO REQUEST OBJECT
  if (action.params) {
    requestObj.params = action.params;
  }

  // ADD FORM VALUE TO REQUEST OBJECT
  if (action.payload) {
    requestObj.data = action.payload;
  }

  // pending state
  next({
    type: pending,
  });

  /**
   * success state [no need to request to server]
   */
  if (!method && !requestLink) {
    try {
      return next({
        type: success,
        payload: action.payload ? action.payload : null,
      });
    } catch (err) {
      return next({
        type: error,
        payload: "Something went wrong.",
      });
    }
  }

  /**
   * add axios interceptors for request
   */
  axios.interceptors.request.use(
    async function (config) {
      if (token) {
        const expire = getTokenExpire();

        if (expire && !isRenewTokenPending) {
          isRenewTokenPending = true;
          await dispatch(requestRefreshToken());
          setTimeout(() => {
            isRenewTokenPending = false;
          }, 0);
        }
      }

      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  /**
   * add axios interceptors for response
   */
  axios.interceptors.response.use(
    (response) => {
      if (response.data.code === "401") {
        dispatch(requestClearAllState());
        localStorage.clear();

        setTimeout(() => (window.location.href = "/login"), 500);

        return response;
      }
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        dispatch(requestClearAllState());
        localStorage.clear();

        setTimeout(() => (window.location.href = "/login"), 500);
      }

      return Promise.reject(error);
    },
  );

  /**
   * success state [request to server]
   */
  axios(requestObj)
    .then((response) => {
      if (response.status === 200) {
        try {
          next({
            type: success,
            payload: response.data,
          });
        } catch (err) {
          console.error(`${pending} RESPONSE ERROR >>> `, err.message);
          next({
            type: error,
            payload: "Something went wrong.",
          });
        }
      } else {
        console.error("RESPONSE STATUS CODE >>> ", response.status);
        next({
          type: error,
          payload: "Something went wrong.",
        });
      }
    })
    .catch((err) => {
      console.error("axios request error >>> ", err.message);
      toast.error(err?.message || "Something went wrong.");
    });
};
