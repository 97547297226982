import React, { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import router from "routes/AppRoutes";
import { CommonToast, LoadingSpinner } from "components/common";

const App = () => {
  return (
    <>
      <CommonToast />

      <Suspense fallback={<LoadingSpinner />}>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
};

export default App;
