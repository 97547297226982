import {
  REQUEST_ISSUERS,
  REQUEST_ISSUERS_SUCCESS,
  REQUEST_ISSUERS_ERROR,
  REQUEST_USER_GROUP,
  REQUEST_USER_GROUP_SUCCESS,
  REQUEST_USER_GROUP_ERROR,
  REQUEST_MERCHANT_USER_GROUP,
  REQUEST_MERCHANT_USER_GROUP_SUCCESS,
  REQUEST_MERCHANT_USER_GROUP_ERROR,
  REQUEST_CONSUMER_USER_GROUP,
  REQUEST_CONSUMER_USER_GROUP_SUCCESS,
  REQUEST_CONSUMER_USER_GROUP_ERROR,
  REQUEST_LIMIT,
  REQUEST_LIMIT_SUCCESS,
  REQUEST_LIMIT_ERROR,
  REQUEST_RATE,
  REQUEST_RATE_SUCCESS,
  REQUEST_RATE_ERROR,
  REQUEST_MERCHANTS,
  REQUEST_MERCHANTS_SUCCESS,
  REQUEST_MERCHANTS_ERROR,
  REQUEST_ALL_MERCHANTS,
  REQUEST_ALL_MERCHANTS_SUCCESS,
  REQUEST_ALL_MERCHANTS_ERROR,
  REQUEST_CONSUMERS,
  REQUEST_CONSUMERS_SUCCESS,
  REQUEST_CONSUMERS_ERROR,
  REQUEST_ALL_CONSUMERS,
  REQUEST_ALL_CONSUMERS_SUCCESS,
  REQUEST_ALL_CONSUMERS_ERROR,
  REQUEST_APP_NAME,
  REQUEST_ROLE_NAME,
  REQUEST_ROLE_NAME_SUCCESS,
  REQUEST_ROLE_NAME_ERROR,
  REQUEST_APP_NAME_SUCCESS,
  REQUEST_APP_NAME_ERROR,
  REQUEST_POINT_CONFIG,
  REQUEST_POINT_CONFIG_SUCCESS,
  REQUEST_POINT_CONFIG_ERROR,
} from "actions/types";

const initialState = {
  loading: false,
  error: null,
  issuers: null,
  userGroupLoading: false,
  userGroupError: null,
  userGroups: null,
  merchantUserGroupLoading: false,
  merchantUserGroupError: null,
  merchantUserGroups: null,
  consumerUserGroupLoading: false,
  consumerUserGroupError: null,
  consumerUserGroups: null,
  limitLoading: false,
  limitError: null,
  limits: [],
  rateLoading: false,
  rateErrror: null,
  rates: [],
  merchantLoading: false,
  merchantError: null,
  merchants: null,
  allMerchantLoading: false,
  allMerchantError: null,
  allMerchants: null,
  consumerLoading: false,
  consumerError: null,
  consumers: null,
  allConsumerLoading: false,
  allConsumerError: null,
  allConsumers: null,
  appNameLoading: false,
  appNameError: null,
  appNameHistory: [],
  roleNameLoading: false,
  roleNameError: null,
  roleNameHistory: [],
  pointLoading: false,
  pointError: null,
  points: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ISSUERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REQUEST_ISSUERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        issuers: action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_ISSUERS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case REQUEST_USER_GROUP:
      return {
        ...state,
        userGroupLoading: true,
        userGroupError: null,
      };
    case REQUEST_USER_GROUP_SUCCESS:
      return {
        ...state,
        userGroupLoading: false,
        userGroupError: null,
        userGroups: action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_USER_GROUP_ERROR:
      return {
        ...state,
        userGroupLoading: false,
        userGroupError: action.payload,
      };

    case REQUEST_MERCHANT_USER_GROUP:
      return {
        ...state,
        merchantUserGroupLoading: true,
        merchantUserGroupError: null,
      };
    case REQUEST_MERCHANT_USER_GROUP_SUCCESS:
      return {
        ...state,
        merchantUserGroupLoading: false,
        merchantUserGroupError: null,
        merchantUserGroups:
          action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_MERCHANT_USER_GROUP_ERROR:
      return {
        ...state,
        merchantUserGroupLoading: false,
        merchantUserGroupError: action.payload,
      };

    case REQUEST_CONSUMER_USER_GROUP:
      return {
        ...state,
        consumerUserGroupLoading: true,
        consumerUserGroupError: null,
      };
    case REQUEST_CONSUMER_USER_GROUP_SUCCESS:
      return {
        ...state,
        consumerUserGroupLoading: false,
        consumerUserGroupError: null,
        consumerUserGroups:
          action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_CONSUMER_USER_GROUP_ERROR:
      return {
        ...state,
        consumerUserGroupLoading: false,
        consumerUserGroupError: action.payload,
      };

    case REQUEST_LIMIT:
      return {
        ...state,
        limitLoading: true,
        limitError: null,
      };
    case REQUEST_LIMIT_SUCCESS:
      return {
        ...state,
        limitLoading: false,
        limitError: null,
        limits: action.payload?.data ? [...action.payload.data] : [],
      };
    case REQUEST_LIMIT_ERROR:
      return {
        ...state,
        limitLoading: false,
        limitError: action.payload,
      };

    case REQUEST_RATE:
      return {
        ...state,
        rateLoading: true,
        rateErrror: null,
      };
    case REQUEST_RATE_SUCCESS:
      return {
        ...state,
        rateLoading: false,
        rateErrror: null,
        rates: action.payload?.data ? [...action.payload.data] : [],
      };
    case REQUEST_RATE_ERROR:
      return {
        ...state,
        rateErrror: false,
        rateErrror: action.payload,
      };

    case REQUEST_MERCHANTS:
      return {
        ...state,
        merchantLoading: true,
        merchantError: null,
        merchants: null,
      };
    case REQUEST_MERCHANTS_SUCCESS:
      return {
        ...state,
        merchantLoading: false,
        merchantError: null,
        merchants: action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_MERCHANTS_ERROR:
      return {
        ...state,
        merchantLoading: false,
        merchantError: action.payload,
      };

    case REQUEST_ALL_MERCHANTS:
      return {
        ...state,
        allMerchantLoading: true,
        allMerchantError: null,
        allMerchants: null,
      };
    case REQUEST_ALL_MERCHANTS_SUCCESS:
      return {
        ...state,
        allMerchantLoading: false,
        allMerchantError: null,
        allMerchants:
          action.payload?.code === "0" ? action.payload?.data : null,
      };
    case REQUEST_ALL_MERCHANTS_ERROR:
      return {
        ...state,
        allMerchantLoading: false,
        allMerchantError: action.payload,
      };

    case REQUEST_CONSUMERS:
      return {
        ...state,
        consumerLoading: true,
        consumerError: null,
      };
    case REQUEST_CONSUMERS_SUCCESS:
      return {
        ...state,
        consumerLoading: false,
        consumerError: null,
        consumers: action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_CONSUMERS_ERROR:
      return {
        ...state,
        consumerLoading: false,
        consumerError: action.payload,
      };

    case REQUEST_ALL_CONSUMERS:
      return {
        ...state,
        allConsumerLoading: true,
        allConsumerError: null,
        allConsumers: null,
      };
    case REQUEST_ALL_CONSUMERS_SUCCESS:
      return {
        ...state,
        allConsumerLoading: false,
        allConsumerError: null,
        allConsumers:
          action.payload?.code === "0" ? action.payload?.data : null,
      };
    case REQUEST_ALL_CONSUMERS_ERROR:
      return {
        ...state,
        allConsumerLoading: false,
        allConsumerError: action.payload,
      };

    case REQUEST_APP_NAME:
      return {
        ...state,
        appNameLoading: true,
        appNameError: null,
      };
    case REQUEST_APP_NAME_SUCCESS:
      return {
        ...state,
        appNameLoading: false,
        appNameError: null,
        appNameHistory: action.payload?.Content.app
          ? [...action.payload?.Content.app]
          : [],
      };
    case REQUEST_APP_NAME_ERROR:
      return {
        ...state,
        appNameLoading: false,
        appNameError: action.payload,
      };

    case REQUEST_ROLE_NAME:
      return {
        ...state,
        roleNameLoading: true,
        roleNameError: null,
      };
    case REQUEST_ROLE_NAME_SUCCESS:
      return {
        ...state,
        roleNameLoading: false,
        roleNameError: null,
        roleNameHistory: action.payload?.Content.app
          ? [...action.payload?.Content.app]
          : [],
      };
    case REQUEST_ROLE_NAME_ERROR:
      return {
        ...state,
        roleNameLoading: false,
        roleNameError: action.payload,
      };

    case REQUEST_POINT_CONFIG:
      return {
        ...state,
        pointLoading: true,
        pointError: null,
      };
    case REQUEST_POINT_CONFIG_SUCCESS:
      return {
        ...state,
        pointLoading: false,
        pointError: null,
        points: action.payload?.code === "0" ? action.payload : null,
      };
    case REQUEST_POINT_CONFIG_ERROR:
      return { ...state, pointLoading: false, pointError: action.payload };

    default:
      return state;
  }
};
