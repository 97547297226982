import { Box } from "@mui/material";
import { ItemTable } from "components/common";
import React from "react";

const ConsumerErrorList = ({ dataRows }) => {
  const columns = [
    { id: "field1", label: "Consumer ID", minWidth: 100 },
    { id: "field2", label: "Consumer Name", minWidth: 100 },
    {
      id: "massage",
      label: "Consumer Error Information",
      minWidth: 100,
    },
  ];
  return (
    <Box>
      <ItemTable
        columns={columns}
        dataRows={dataRows}
        noPagination={true}
        errorHeader={true}
      />
    </Box>
  );
};

export default ConsumerErrorList;
