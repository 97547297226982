import { Controller } from "react-hook-form";
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import { daDK } from "@mui/x-date-pickers";

const SelectBox = ({ label, name, control, options, disabled }) => {
  return (
    <Controller
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => (
        <FormControl
          fullWidth
          size="small"
          margin="normal"
          error={error ? true : false}
        >
          <InputLabel id={label}>{label}</InputLabel>
          <Select
            value={value}
            labelId={label}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            disabled={disabled ?? false}
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error ? error.message : ""}</FormHelperText>
        </FormControl>
      )}
      name={name}
      control={control}
    />
  );
};

SelectBox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
};

export default SelectBox;
