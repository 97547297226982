import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { LoadingSpinner } from "components/common";
import { COLORS, pgSize } from "constants";

const ItemTable = ({
  loading,
  columns,
  dataRows,
  noPagination,
  requestDataByPageNo,
  errorHeader,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(pgSize);

  useEffect(() => {
    dataRows
      ? dataRows?.pageSize && setRowsPerPage(dataRows?.pageSize)
      : setRowsPerPage(pgSize);
  }, [dataRows]);

  const handleChangePage = (event, newPage) => {
    requestDataByPageNo(rowsPerPage, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value;
    setRowsPerPage(perPage);

    requestDataByPageNo(perPage, 1);
  };

  return (
    <Box sx={{ position: "relative" }}>
      {loading && <LoadingSpinner />}

      <TableContainer sx={{ mt: 4, maxHeight: 640 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    color: errorHeader ? "#F00" : "#000",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {noPagination ? (
              dataRows?.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    {columns.map((column, index) => {
                      const value = row[column.id];
                      const status = row[column.status];

                      return (
                        <TableCell key={index} align={column.align}>
                          {column.format
                            ? status || status === 0
                              ? column.format(value, status)
                              : column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : dataRows?.count ? (
              dataRows?.data?.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    {columns.map((column, index) => {
                      const value = row[column.id];
                      const status = row[column.status];

                      return (
                        <TableCell key={index} align={column.align}>
                          {column.format
                            ? status || status === 0
                              ? column.format(value, status)
                              : column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={columns.length}
                  sx={{ color: COLORS.error }}
                >
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!noPagination && (
        <TablePagination
          rowsPerPageOptions={[pgSize, 50, 100, 500, 1000]}
          component="div"
          count={dataRows?.count ?? 0}
          rowsPerPage={rowsPerPage}
          page={
            !dataRows?.count || dataRows?.count <= 0 ? 0 : dataRows.pageNo - 1
          }
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

export default ItemTable;
