import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";
import NotStartedOutlinedIcon from "@mui/icons-material/NotStartedOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import AddLinkOutlinedIcon from "@mui/icons-material/AddLinkOutlined";
import { TooltipIconButton, CommonChip } from "components/common";

export const handleOperationButton = (cb, title, value, operation) => (
  <TooltipIconButton title={title} cb={() => cb(value)}>
    {operation === "detail" && (
      <ContentPasteSearchOutlinedIcon fontSize="inherit" />
    )}
    {operation === "pause" && <PauseCircleOutlinedIcon fontSize="inherit" />}
    {operation === "stop" && <StopCircleOutlinedIcon fontSize="inherit" />}
    {operation === "play" && <NotStartedOutlinedIcon fontSize="inherit" />}
    {operation === "edit" && <NoteAltOutlinedIcon fontSize="inherit" />}
    {operation === "delete" && <DeleteOutlinedIcon fontSize="inherit" />}
    {operation === "sendMail" && (
      <ForwardToInboxOutlinedIcon fontSize="inherit" />
    )}
    {operation === "attachFile" && <AddLinkOutlinedIcon fontSize="inherit" />}
  </TooltipIconButton>
);

export const handleStatus = (label, color) => (
  <CommonChip label={label} color={color} />
);
